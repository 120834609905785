import React, { useEffect } from "react"
import { Layout, notification } from "antd"
import { useAuthStore } from "@store/auth.store"
import { useNavStore, usePage } from "@store/nav.store"
import Navbar from "./components/Navbar"
import { useUserChannel } from "@hooks"
import { SubscriptionContext } from "@cable"

import "./AppLayout.css"

const { Header } = Layout

const AppLayout = () => {
  const [notificationApi, contextHolder] = notification.useNotification()
  useUserChannel(notificationApi)

  const loggedIn = useAuthStore((state) => state.loggedIn)
  const setPageByPath = useNavStore((state) => state.setPageByPath)
  const Page = usePage()

  useEffect(() => {
    if (loggedIn) {
      const currentPath = window.location.pathname
      setPageByPath(currentPath)
    } else {
      setPageByPath("/")
    }

    const handlePopState = (event) => {
      if (event.state && event.state.path && loggedIn) {
        setPageByPath(event.state.path || "/")
      } else {
        setPageByPath("/")
      }
    }

    window.addEventListener("popstate", handlePopState)
    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [loggedIn, setPageByPath])

  return (
    <SubscriptionContext.Provider value={SubscriptionContext.subscription}>
      <Layout data-testid="ant-layout">
        {contextHolder}
        <nav className="navbar">
          <Header className="nav-header">
            <div className="logo">
              <h3 className="brand-font">PM.pro</h3>
            </div>
            <Navbar />
          </Header>
        </nav>
        <Page />
      </Layout>
    </SubscriptionContext.Provider>
  )
}

AppLayout.displayName = "AppLayout"
AppLayout.whyDidYouRender = true

export default AppLayout
