import { Form, Slider } from "antd"
import { debounce } from "lodash"

import {
  useAdvancedPromptStore,
  useCfgScale,
} from "@store/advanced_prompt.store"

import ScrollableInputNumber from "@shared/components/ScrollableInputNumber"

const CfgScale = () => {
  const [form] = Form.useForm()
  const cfgScale = useCfgScale()
  const setCfgScale = useAdvancedPromptStore((state) => state.setCfgScale)

  const fields = [
    {
      name: "cfgScale",
      value: cfgScale,
    },
  ]

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.cfgScale) {
      setCfgScale(changedValues.cfgScale)
    }
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item
        name="cfgScale"
        label="Cfg"
        style={{ marginBottom: 0 }}
        labelCol={{ span: 10 }}
      >
        <ScrollableInputNumber
          min={0.1}
          max={30}
          name="cfgScale"
          form={form}
          callback={handleValuesChange}
          precision={1}
          step={0.1}
        />
      </Form.Item>
      <Form.Item name="cfgScale">
        <Slider min={0.1} max={30} precision={1} step={0.1} />
      </Form.Item>
    </Form>
  )
}

CfgScale.displayName = "CfgScale"
CfgScale.whyDidYouRender = true

export default CfgScale
