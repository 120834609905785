import React, { useState } from "react"
import { Form, Input, Space, Button } from "antd"

const LoginForm = ({ login, signup, setVisible }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const handleLogin = (values) => {
    login(values)
    setVisible(false)
  }

  // TODO: Implement signup
  // const handleSignup = (e) => {
  //   signup({ email, password })
  //   setVisible(false)
  // }

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={handleLogin}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        label="Email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        rules={[{ required: true, message: "Please input your email!" }]}
        style={{ marginBottom: 0 }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        rules={[{ required: true, message: "Please input your password!" }]}
        style={{ marginBottom: 0 }}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{ offset: 8, span: 16 }}
        style={{ marginBottom: 0, marginTop: 16 }}
      >
        <Space wrap>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
          {/* <Button onClick={handleSignup}>Signup</Button> */}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
