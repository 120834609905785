import { theme, Typography } from "antd"
const { useToken } = theme
const { Text } = Typography

const NegativePromptText = ({ text }) => {
  const { token } = useToken()
  const negativeIconStyle = {
    color: token.colorError,
    marginRight: token.marginXS,
  }

  return (
    <>
      <i className="fa-duotone fa-square-quote" style={negativeIconStyle} />
      <Text copyable>{text}</Text>
    </>
  )
}

export default NegativePromptText
