import React, { useRef } from "react"
import { Image, Row, Col } from "antd"

import { usePromptGeneratedImageIdsById } from "@store/assisted_prompt.store"

import GeneratedImage from "@shared/components/GeneratedImage"

const ResultImages = ({ id }) => {
  const containerRef = useRef(null)
  const imageIds = usePromptGeneratedImageIdsById(id)

  return (
    <div ref={containerRef}>
      <Image.PreviewGroup
        preview={{ getContainer: () => containerRef.current }}
      >
        <Row gutter={[16, 16]}>
          {imageIds.map((id) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} key={id}>
              <GeneratedImage key={id} id={id} />
            </Col>
          ))}
        </Row>
      </Image.PreviewGroup>
    </div>
  )
}

export default ResultImages
