import { useEffect } from "react"
import ReactGA from "react-ga4"
import { Space, Layout } from "antd"

import PromptForm from "./components/PromptForm"
import Sidebar from "./components/Sidebar"
import Timeline from "./components/Timeline"

import "./index.css"

const { Content, Sider } = Layout

const Advanced = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/advanced" })
  }, [])

  return (
    <Layout>
      <Sider width={300} className="advanced-sider">
        <Space direction="vertical" style={{ display: "flex" }}>
          <Sidebar />
        </Space>
      </Sider>
      <Content>
        <section data-testid="advanced-prompt" className="advanced-prompt">
          <PromptForm />
          <Timeline />
        </section>
      </Content>
    </Layout>
  )
}

Advanced.displayName = "Advanced"
Advanced.whyDidYouRender = true

export default Advanced
