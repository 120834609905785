import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { Skeleton, Typography, Button, Flex, Layout } from "antd"
import axios from "axios"

import "./index.css"

const { Content } = Layout
const { Title, Paragraph } = Typography

const Landing = () => {
  const [image, setImage] = useState(null)

  const giphyApiKey = "LKrIKbFgiAe3spyY6K45RacZ8m7Vilvs"
  const giphyTag = "waiting"
  const getGiphy = () => {
    axios
      .get(
        `https://api.giphy.com/v1/gifs/random?api_key=${giphyApiKey}&tag=${giphyTag}&rating=g`,
      )
      .then((response) => {
        setImage(response.data.data.images.original.url)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDiscordClick = () => {
    ReactGA.event({
      category: "Landing",
      action: "Discord",
    })
    window.open("https://discord.gg/t2sqg8hm9Q", "_blank")
  }

  useEffect(() => {
    if (image) return

    ReactGA.send({ hitType: "pageview", page: "/" })
    getGiphy()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Flex vertical className="landing">
        <Title level={2} className="title">
          PromptMaster.pro
        </Title>
        <Title level={4} className="subtitle">
          Unleashing Language Creativity in AI-Driven Art
        </Title>
        <Flex justify="space-around" align="center" className="box">
          <Flex vertical align="flex-start">
            <Title level={4} className="subtitle">
              Wait for it
            </Title>
            <div className="text-content">
              <Paragraph>
                Elevate your art with precision-engineered prompts <br />
                for AI-powered imagery.
              </Paragraph>
              <ul className="has-text-left">
                <li>
                  <strong>Word Library:</strong> Perfect word choice with visual
                  examples.
                </li>
                <li>
                  <strong>AI Chat:</strong> Interactive prompt fine-tuning.
                </li>
                <li>
                  <strong>Language Tools:</strong> Synonyms and definitions for
                  nuanced prompting experiments.
                </li>
                <li>
                  <strong>Public Gallery:</strong> Share and discover best
                  prompts and images.
                </li>
              </ul>
              <Paragraph>
                Join us this winter for an exclusive closed alpha release,{" "}
                <br />
                available to our supporters on Patreon and Ko-Fi.
              </Paragraph>
            </div>
            <Button
              type="primary"
              size="large"
              block
              onClick={handleDiscordClick}
            >
              Join Our Discord
            </Button>
          </Flex>

          <Flex>
            {image === null ? (
              <Skeleton />
            ) : (
              <img id="giphy-img" alt="Wait for it..." src={image} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Content>
  )
}

export default Landing
