import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react"
import ReactGA from "react-ga4"
import { Virtuoso } from "react-virtuoso"
import { Space, Skeleton, Layout } from "antd"

import { useSynced, usePromptsIds } from "@store/assisted_prompt.store"
import { SubscriptionContext } from "@cable"

import Prompt from "./components/Prompt"
import ChatInput from "./components/ChatInput"
import TextSelector from "@shared/components/TextSelector"

import "./index.css"

const { Content } = Layout

const Assisted = () => {
  const subscription = useContext(SubscriptionContext)
  const synced = useSynced()
  const promptsIds = usePromptsIds()
  const inputRef = useRef(null)
  const frameRef = useRef(null)
  const indexOffset = 10000 // TODO: replace with the current_user.assisted_prompts.count from the backend
  const [state, setState] = useState({
    items: [],
    firstItemIndex: indexOffset,
  })

  const setItems = (items) => {
    setState((state) => {
      if (state.items === items) return state
      return { ...state, items: items }
    })
  }

  // On mount operations:
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/assisted" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const startReached = useCallback(
    () => {
      const { id } = state.items[0]
      subscription.perform("assisted_prompts_paginate_up_from", { id: id })
    },
    [state.items], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Track when promptsIds receives new items on top and decrease firstItemIndex by the number of new items
  useEffect(() => {
    if (promptsIds.length === 0) return

    const newItemsCount = promptsIds.length - state.items.length
    if (state.items.length !== 0 && newItemsCount > 0) {
      setState((state) => ({
        ...state,
        firstItemIndex: state.firstItemIndex - newItemsCount,
      }))
    }

    setItems(promptsIds)
  }, [promptsIds]) // eslint-disable-line react-hooks/exhaustive-deps

  const itemContent = (_index, item) => <Prompt key={item.id} {...item} />

  return (
    <Content>
      <section
        className="assisted-prompt"
        ref={frameRef}
        data-testid="assisted-prompt"
      >
        {synced ? (
          <Virtuoso
            style={{ flexGrow: 1 }}
            firstItemIndex={state.firstItemIndex}
            initialTopMostItemIndex={state.items.length - 1}
            data={state.items}
            startReached={startReached}
            itemContent={itemContent}
            atTopThreshold={600}
            computeItemKey={(_index, item) => item.id}
            increaseViewportBy={2000}
          />
        ) : (
          <Skeleton active={true} style={{ flexGrow: 1 }} />
        )}
        <div className="input-area" ref={inputRef}>
          <ChatInput />
        </div>
        <TextSelector copy />
      </section>
    </Content>
  )
}

Assisted.displayName = "Assisted"
Assisted.whyDidYouRender = false

export default Assisted
