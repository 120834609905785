import {
  theme,
  Button,
  Space,
  Tooltip,
  Row,
  Col,
  Form,
  Select,
  Input,
} from "antd"
import { debounce } from "lodash"

import { useAdvancedPromptStore } from "@store/advanced_prompt.store"

import ScrollableInputNumber from "@shared/components/ScrollableInputNumber"

const { useToken } = theme

const ParamsForm = () => {
  const [form] = Form.useForm()
  const {
    width,
    height,
    steps,
    cfg_scale: cfgScale,
    seed,
    sampler,
    availableSamplers,
  } = useAdvancedPromptStore((state) => state)
  const setPrompt = useAdvancedPromptStore((state) => state.setPrompt)

  const { token } = useToken()
  const tooltipColor = token.colorPrimary

  const fields = [
    {
      name: "width",
      value: width,
    },
    {
      name: "height",
      value: height,
    },
    {
      name: "steps",
      value: steps,
    },
    {
      name: "cfgScale",
      value: cfgScale,
    },
    {
      name: "seed",
      value: seed,
    },
    {
      name: "sampler",
      value: sampler,
    },
  ]
  const samplerOptions = availableSamplers.map((sampler) => ({
    label: sampler,
    value: sampler,
  }))

  const handleRotate = () => {
    // Swap width and height
    const newWidth = height
    const newHeight = width
    form.setFieldsValue({
      width: newWidth,
      height: newHeight,
    })
    setPrompt({
      width: newWidth,
      height: newHeight,
    })
  }

  const handlePasteSeedButton = () => {
    navigator.clipboard.readText().then((text) => {
      form.setFieldsValue({
        seed: text,
      })
      setPrompt({
        seed: text,
      })
    })
  }

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.cfgScale) {
      changedValues["cfg_scale"] = changedValues.cfgScale
      delete changedValues.cfgScale
    }
    setPrompt(changedValues)
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item
            name="width"
            label={
              <Tooltip title="Width" color={tooltipColor}>
                <i className="fa-duotone fa-arrows-left-right"></i>
              </Tooltip>
            }
          >
            <ScrollableInputNumber
              min={64}
              max={1024}
              form={form}
              name="width"
              callback={handleValuesChange}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="height"
            label={
              <Tooltip title="Height" color={tooltipColor}>
                <i className="fa-duotone fa-arrows-up-down"></i>
              </Tooltip>
            }
          >
            <ScrollableInputNumber
              min={64}
              max={1024}
              form={form}
              name="height"
              callback={handleValuesChange}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Tooltip title="Rotate" color={tooltipColor}>
            <Button
              icon={<i className="fa-duotone fa-rotate-right"></i>}
              onClick={handleRotate}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Space.Compact>
            <Form.Item
              name="seed"
              label={
                <Tooltip title="Seed" color={tooltipColor}>
                  <i className="fa-duotone fa-dice"></i>
                </Tooltip>
              }
            >
              <Input placeholder="Random" allowClear />
            </Form.Item>
            <Button
              onClick={handlePasteSeedButton}
              icon={<i className="fa-duotone fa-paste"></i>}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="sampler"
            label={
              <Tooltip title="Sampler" color={tooltipColor}>
                <i className="fa-duotone fa-magic"></i>
              </Tooltip>
            }
          >
            <Select options={samplerOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="steps"
            label={
              <Tooltip title="Steps" color={tooltipColor}>
                <i className="fa-duotone fa-arrows-spin"></i>
              </Tooltip>
            }
          >
            <ScrollableInputNumber
              min={1}
              max={100}
              form={form}
              name="steps"
              callback={handleValuesChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="cfgScale"
            label={
              <Tooltip title="CFG scale" color={tooltipColor}>
                <i className="fa-duotone fa-gauge-simple-low"></i>
              </Tooltip>
            }
          >
            <ScrollableInputNumber
              min={0.1}
              max={30}
              form={form}
              name="cfgScale"
              callback={handleValuesChange}
              precision={1}
              step={0.1}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ParamsForm
