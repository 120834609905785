import React, { useMemo } from "react"
import { Card } from "antd"
// import { useCheckNewReference } from "@hooks"
import { useImageById } from "@store/generated_image.store"
import Image from "./Image"
import DownloadAction from "./DownloadAction"
import EditAction from "./EditAction"
import InfoAction from "./InfoAction"

const GeneratedImage = ({ id }) => {
  const image = useImageById(id)
  // useCheckNewReference(image, `GeneratedImage ${id}`)

  const MemoImage = useMemo(() => <Image {...image} />, [image])

  const { state } = image
  const actions =
    state === "completed"
      ? [
          <DownloadAction image={image} />,
          <EditAction image={image} />,
          <InfoAction image={image} />,
        ]
      : null

  return (
    <Card className="server-response image" actions={actions}>
      {MemoImage}
    </Card>
  )
}

GeneratedImage.displayName = "GeneratedImage"
GeneratedImage.whyDidYouRender = true

export default GeneratedImage
