import React, { useState } from "react"
import { Button, Drawer } from "antd"
import LeftMenu from "./LeftMenu"
import Authentication from "./Authentication"

const Navbar = () => {
  const [visible, setVisible] = useState(false)
  const showDrawer = () => {
    setVisible(!visible)
  }

  // TODO: Check do we need this?
  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  // let { pathname: location } = useLocation()
  // useEffect(() => {
  //   setVisible(false)
  // }, [location])
  // Upto here

  // TODO: Do not use drawer for mobile menu because we'll use it for other purposes
  return (
    <div className="navbar-menu">
      <div className="leftMenu">
        <LeftMenu mode={"horizontal"} />
      </div>
      <Button className="menuButton" type="text" onClick={showDrawer}>
        <i className="fas fa-bars" />
      </Button>
      <div className="rightMenu">
        <Authentication mode={"horizontal"} />
      </div>

      <Drawer
        title={"Brand Here"}
        placement="right"
        closable={true}
        onClose={showDrawer}
        open={visible}
        style={{ zIndex: 99999 }}
      >
        <LeftMenu mode={"inline"} />
        <Authentication mode={"inline"} />
      </Drawer>
    </div>
  )
}

export default Navbar
