import React, { useEffect, useRef, useState } from "react"
import { theme, Input, Space, Tag, Tooltip } from "antd"

import { useAdvancedPromptStore } from "@store"

// TODO: Implement values generator UI for non-token values
const GridNodes = ({ axis }) => {
  const [setGridAxis, removeFromGridAxis, setGridAxesType] =
    useAdvancedPromptStore((state) => [
      state.setGridAxis,
      state.removeFromGridAxis,
      state.setGridAxesType,
    ])
  const gridAxesTypes = useAdvancedPromptStore((state) => state.gridAxesTypes)
  const gridAxes = useAdvancedPromptStore((state) => state.gridAxes)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState("")
  const inputRef = useRef(null)
  const editInputRef = useRef(null)

  const { token } = theme.useToken()

  const tagStyle = {
    fontSize: 16,
    lineHeight: token.lineHeight,
    userSelect: "none",
    cursor: "pointer",
  }
  const tagInputStyle = {
    ...tagStyle,
    width: 128,
    height: token.sizeBase,
    marginInlineEnd: 8,
    verticalAlign: "top",
  }
  const tagPlusStyle = {
    ...tagStyle,
    // background: token.colorBgContainer,
    // borderStyle: "dashed",
  }

  const disabledTagStyle = {
    ...tagPlusStyle,
    color: token.colorTextDisabled,
    cursor: "not-allowed",
  }

  const tags = gridAxesTypes[axis] ? gridAxes[gridAxesTypes[axis]][axis] : []

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [editInputValue])

  const handleClose = (removedTag) => {
    removeFromGridAxis(axis, removedTag)
  }

  const showInput = () => {
    if (tags.length >= 10) return

    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    const newValues = inputValue.split(",")
    setGridAxis(axis, prepareSet([...tags, ...newValues]))
    setInputVisible(false)
    setInputValue("")
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const prepareSet = (tags) =>
    tags
      // trim whitespace
      .map((item) => item.trim())
      // remove empty strings
      .filter((item) => item)
      // unique
      .filter((item, index, self) => self.indexOf(item) === index)
      // cut to 10 allowed per axis
      .slice(0, 10)

  const handleEditInputConfirm = () => {
    const newValues = editInputValue.split(",")
    let newTags = [...tags]
    // remove old value and add new ones at the same index
    newTags.splice(editInputIndex, 1, ...newValues)
    setGridAxis(axis, prepareSet(newTags))
    setEditInputIndex(-1)
    setEditInputValue("")
  }

  const handleClear = () => {
    setGridAxesType(axis, null)
  }

  const isLongTag = (tag) => tag.length > 20

  const EditTag = ({ tag }) => (
    <Input
      ref={editInputRef}
      key={tag}
      size="small"
      style={tagInputStyle}
      value={editInputValue}
      onChange={handleEditInputChange}
      onBlur={handleEditInputConfirm}
      onPressEnter={handleEditInputConfirm}
    />
  )

  const RegularTag = ({ tag, index }) => (
    <Tag
      key={tag}
      closable={index !== 0}
      style={tagStyle}
      onClose={() => handleClose(tag)}
    >
      <span
        onDoubleClick={(e) => {
          if (index !== 0) {
            setEditInputIndex(index)
            setEditInputValue(tag)
            e.preventDefault()
          }
        }}
      >
        {isLongTag(tag) ? `${tag.slice(0, 20)}...` : tag}
      </span>
    </Tag>
  )

  return gridAxesTypes[axis] ? (
    <Space size={[0, 8]} wrap>
      {tags.map((tag, index) =>
        editInputIndex === index ? (
          <EditTag tag={tag} key={tag} />
        ) : isLongTag(tag) ? (
          <Tooltip title={tag} key={tag}>
            <RegularTag tag={tag} index={index} />
          </Tooltip>
        ) : (
          <RegularTag tag={tag} index={index} key={tag} />
        ),
      )}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tooltip title="Add">
          <Tag
            color="success"
            style={tags.length < 10 ? tagPlusStyle : disabledTagStyle}
            icon={<i className="fa-duotone fa-plus" />}
            onClick={showInput}
          />
        </Tooltip>
      )}
      {tags.length > 0 && (
        <Tooltip title="Clear">
          <Tag
            color="error"
            style={tagPlusStyle}
            icon={<i className="fa-duotone fa-trash" />}
            onClick={handleClear}
          />
        </Tooltip>
      )}
    </Space>
  ) : (
    ""
  )
}

export default GridNodes
