import React, { useEffect, useRef } from "react"
import { InputNumber } from "antd"
import { debounce } from "lodash"

const ScrollableInputNumber = (props) => {
  const inputNumberRef = useRef(null)
  const { name, min, max } = props
  const { form, callback, ...rest } = props
  const precision = props.precision || 0
  // Calc deltaY divider based on precision
  const slowDownDivider = Math.pow(10, precision + 2)

  const handleCallback = debounce((value) => {
    if (callback && typeof callback === "function") callback(value)
  }, 100)

  const handleWheel = (event) => {
    event.preventDefault()

    // Get current field value
    const currentValue = form.getFieldValue(name)

    // Calculate increment based on scroll speed
    const increment = Math.abs(event.deltaY) / slowDownDivider

    let newValue
    if (event.deltaY < 0) {
      newValue = Math.min((currentValue || 0) + increment, max)
    } else {
      newValue = Math.max((currentValue || 0) - increment, min)
    }

    const newValueWithPrecision = parseFloat(newValue.toFixed(precision))

    // Set new value to the form field
    form.setFieldValue(name, newValue)
    handleCallback({ [name]: newValueWithPrecision })
  }

  useEffect(() => {
    const node = inputNumberRef.current
    node.addEventListener("wheel", handleWheel, { passive: false })
    return () => {
      node.removeEventListener("wheel", handleWheel)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <InputNumber precision={precision} ref={inputNumberRef} {...rest} />
}

export default ScrollableInputNumber
