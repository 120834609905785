import { Avatar } from "antd"
import { useStats } from "@store/app.store"

// TODO: Replace with user's profile menu
const Stats = () => {
  // Get each server status count
  const { idle, requested, timeouted, failed, unavailable } = useStats(
    (state) => state,
  )
  const total = idle + requested + timeouted + failed + unavailable

  // set icon color based on servers availability
  // red af all servers are unavailable, yellow if some servers are idle, green if all servers are idle
  const color =
    unavailable === total
      ? "red"
      : idle === total
      ? "green"
      : idle > 0
      ? "yellow"
      : "red"

  return (
    <Avatar
      icon={<i className="fa-solid fa-microchip-ai" style={{ color }} />}
    />
  )
}

export default Stats
