import { useEffect, useRef } from "react"
import { createUserChannelSubscription, SubscriptionContext } from "@cable"
import {
  useAppStore,
  useAuthStore,
  useGeneratedImageStore,
  useStableDiffusionPromptStore,
  useAssistedPromptStore,
  useAdvancedPromptStore,
  useNlpStore,
} from "@store"
import { useNotification } from "@hooks"
// import { useCheckNewReference } from "@hooks"

/**
 * This hook sets up a user channel subscription using the `createUserChannelSubscription` function from the "@cable" package.
 * It listens for updates on the user channel and updates the relevant stores based on the received data.
 * The hook also handles error notifications and session expiration.
 * @returns {void}
 */
const useUserChannel = (notificationApi) => {
  const subscriptionInitializedRef = useRef(false)
  const { loggedIn, jwt, logout } = useAuthStore((state) => state)

  const setAssistedPrompts = useAssistedPromptStore((state) => state.setPrompts)
  const prependAssistedPrompts = useAssistedPromptStore(
    (state) => state.prependPrompts,
  )
  const addAssistedPrompt = useAssistedPromptStore((state) => state.addPrompt)

  const setStableDiffusionPrompts = useStableDiffusionPromptStore(
    (state) => state.setPrompts,
  )
  const addStableDiffusionPrompt = useStableDiffusionPromptStore(
    (state) => state.addPrompt,
  )
  const addStableDiffusionPrompts = useStableDiffusionPromptStore(
    (state) => state.addPrompts,
  )

  const setImages = useGeneratedImageStore((state) => state.setImages)
  const addImage = useGeneratedImageStore((state) => state.addImage)
  const addImages = useGeneratedImageStore((state) => state.addImages)

  const setAdvancedPrompt = useAdvancedPromptStore((state) => state.setPrompt)
  const setAdvancedPrompts = useAdvancedPromptStore((state) => state.setPrompts)
  const prependAdvancedPrompt = useAdvancedPromptStore(
    (state) => state.prependPrompt,
  )
  const appendAdvancedPrompts = useAdvancedPromptStore(
    (state) => state.appendPrompts,
  )
  const setTokens = useNlpStore((state) => state.setTokens)
  const setStats = useAppStore((state) => state.setStats)

  const { openErrorNotification, openNoticeNotification } =
    useNotification(notificationApi)

  const onRejected = () => {
    if (loggedIn) {
      openErrorNotification(
        "Your session has expired",
        "Please log in again to continue",
        { duration: 0 },
      )
      logout()
    } else {
      openErrorNotification("Connection rejected", "Please log in to continue")
    }
  }

  const onConnected = () => {
    // TODO: Add connection indicator
    openNoticeNotification("Connected to server")
  }

  const onDisconnected = () => {
    openErrorNotification("Disconnected from server", "Reconnecting...")
  }

  const onReceived = (data) => {
    // console.log("Received data from user channel", data)
    Object.keys(data).forEach((key) => {
      // Get the top key from the data object as a marker which store should be updated and the value as the data
      const [value] = Object.values(data[key])

      switch (key) {
        case "assisted_prompt":
          addAssistedPrompt(value)
          break
        case "assisted_prompts_prepend":
          prependAssistedPrompts(data[key])
          break
        case "advanced_prompt":
          prependAdvancedPrompt(value)
          break
        case "advanced_prompts_append":
          appendAdvancedPrompts(data[key])
          break
        case "nlp_tokens":
          setTokens(data[key])
          break
        case "stable_diffusion_prompt":
          addStableDiffusionPrompt(value)
          break
        case "stable_diffusion_prompts_add":
          addStableDiffusionPrompts(data[key])
          break
        case "generated_image":
          addImage(value)
          break
        case "generated_images_add":
          addImages(data[key])
          break
        case "sync_all":
          processSyncAll(data[key])
          break
        case "stats":
          setStats(value)
          break
        default:
          console.log("Unknown event received from user channel", {
            data,
            key,
            value,
          })
          break
      }
    })
  }

  // useCheckNewReference(loggedIn, "useUserChannel loggedIn")

  useEffect(() => {
    if (!loggedIn || subscriptionInitializedRef.current) {
      return
    }

    const subscription = createUserChannelSubscription(
      jwt,
      onConnected,
      onReceived,
      onRejected,
      onDisconnected,
    )

    SubscriptionContext.subscription = subscription
    subscriptionInitializedRef.current = true

    return () => {
      subscription.unsubscribe()
      subscriptionInitializedRef.current = false
    }
  }, [loggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

  const processSyncAll = (data) => {
    setAssistedPrompts(data.assisted_prompts)
    setAdvancedPrompts(data.advanced_prompts)
    setStableDiffusionPrompts(data.stable_diffusion_prompts)
    setImages(data.generated_images)
    setAdvancedPrompt(data.image_params)
  }
}

export { useUserChannel }
