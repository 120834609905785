import { create } from "zustand"

const useAppStore = create((set) => ({
  stats: {},
  setStats: (stats) => set({ stats }),
}))

const useStats = () => useAppStore((state) => state.stats) || {}

export { useAppStore, useStats }
