import { useEffect, useState } from "react"
import { Collapse } from "antd"

import { useAdvancedPromptStore } from "@store"

import PromptParameters from "./PromptParameters"
import GridParameters from "./GridParameters"

const Sidebar = () => {
  const [activeKey, setActiveKey] = useState("prompt-parameters")
  const gridAxesTypes = useAdvancedPromptStore((state) => state.gridAxesTypes)

  const items = [
    {
      key: "prompt-parameters",
      label: "Prompt Parameters",
      children: <PromptParameters />,
    },
    {
      key: "grid-parameters",
      label: "Grid Parameters",
      children: <GridParameters />,
    },
  ]

  useEffect(() => {
    if (gridAxesTypes.x || gridAxesTypes.y) {
      setActiveKey("grid-parameters")
    }
  }, [gridAxesTypes])

  return (
    <Collapse
      items={items}
      activeKey={activeKey}
      onChange={setActiveKey}
      accordion
    />
  )
}

export default Sidebar
