import { useState } from "react"

import { Button, Popover, Tooltip } from "antd"

import ParamsForm from "./ParamsForm"

const PromptParameters = () => {
  const [state, setState] = useState({
    tooltipOpen: false,
    popoverOpen: false,
  })

  const handleTooltipMouseEnter = () => {
    if (!state.tooltipOpen && !state.popoverOpen) {
      setState({ ...state, tooltipOpen: true })
    }
  }

  const handleTooltipMouseLeave = () => {
    if (state.tooltipOpen) {
      setState({ ...state, tooltipOpen: false })
    }
  }

  const handlePopoverVisibleChange = (open) => {
    const newState = { ...state, popoverOpen: open }
    if (state.tooltipOpen) newState.tooltipOpen = false
    setState(newState)
  }

  return (
    <Popover
      content={<ParamsForm />}
      title="Image settings"
      trigger="click"
      placement="left"
      onOpenChange={handlePopoverVisibleChange}
    >
      <Tooltip
        title="Image settings"
        placement="left"
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
        open={state.tooltipOpen}
      >
        <Button icon={<i className="fa-duotone fa-sliders" />} />
      </Tooltip>
    </Popover>
  )
}

export default PromptParameters
