import { theme, Form, Select, Tooltip } from "antd"
import {
  useAvailableSamplers,
  useSampler,
  useAdvancedPromptStore,
} from "@store/advanced_prompt.store"

const { useToken } = theme

const Sampler = () => {
  const [form] = Form.useForm()
  const setSampler = useAdvancedPromptStore((state) => state.setSampler)
  const samplers = useAvailableSamplers()
  const sampler = useSampler()
  const options = samplers.map((sampler) => ({
    label: sampler,
    value: sampler,
  }))
  const fields = [
    {
      name: "sampler",
      value: sampler,
    },
  ]
  const { token } = useToken()
  const tooltipColor = token.colorPrimary

  const handleValuesChange = (changedValues) => {
    if (changedValues.sampler) {
      setSampler(changedValues.sampler)
    }
  }

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item
        label={
          <Tooltip title="Sampler" color={tooltipColor} placement="topRight">
            <i className="fa-duotone fa-magic"></i>
          </Tooltip>
        }
        name="sampler"
      >
        <Select name="sampler" options={options} />
      </Form.Item>
    </Form>
  )
}

Sampler.displayName = "Sampler"

export default Sampler
