// Ant Design custom theme
// https://ant.design/docs/react/customize-theme
import { theme } from "antd"

const darkTheme = {
  algorithm: theme.darkAlgorithm,
  token: {
    fontFamily: "Source Code Pro, monospace",
    colorPrimary: "#ffb454",
    colorPrimaryActive: "#ffc600",
    colorPrimaryHover: "#ffc600",
    colorPrimaryBg: "#15232d",
    colorBgContainer: "#183549",
    colorBgLayout: "#15232d",
    colorBgElevated: "rgba(21, 35, 45, 0.9)",
    colorBgSpotlight: "rgba(21, 35, 45, 0.9)",
    colorBgContainerDisabled: "rgba(255, 180, 84, 0.20)",
    colorText: "rgba(255, 255, 255, 0.90)",
    colorTextSecondary: "#9ef68d",
    colorTextTertiary: "#9dffff",
    colorTextQuaternary: "rgba(157, 255, 255, 0.5)",
    colorTextPlaceholder: "rgba(157, 255, 255, 0.5)",
    colorTextDescription: "rgba(255, 255, 255, 0.9)",
    colorBorder: "rgba(255, 255, 255, 0.2)",
    colorBorderSecondary: "#ffb454",
    colorSuccess: "#5acd37",
    colorWarning: "#ffc600",
    colorError: "#f16381",
    colorInfo: "#0087ff",
    colorBgBase: "#15232d",
    wireframe: true,
  },
  components: {
    InputNumber: {
      controlWidth: 70,
    },
    Button: {
      defaultBorderColor: "rgba(255, 255, 255, 0.2)",
    },
  },
}

export default darkTheme
