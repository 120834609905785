import { create } from "zustand"
import { login, signup, logout, getCurrentUser } from "@services/users"

const useAuthStore = create((set, get) => ({
  loggedIn: localStorage.getItem("jwt") ? true : false,
  jwt: localStorage.getItem("jwt") || null,
  user: JSON.parse(localStorage.getItem("user")) || null,
  login: async (email, password) => {
    try {
      const response = await login(email, password)
      const jwt = response.headers.authorization
      console.log(response.data)
      localStorage.setItem("jwt", jwt)
      localStorage.setItem("user", JSON.stringify(response.data))

      set({ loggedIn: true, jwt, user: response.data })
    } catch (error) {
      console.log(error)
    }
  },
  signup: async (email, password) => {
    const response = await signup(email, password)
    const jwt = response.headers.authorization

    localStorage.setItem("jwt", jwt)
    localStorage.setItem("user", JSON.stringify(response.data))

    set({ loggedIn: true, jwt, user: response.data })
  },
  logout: async () => {
    try {
      await logout(get().jwt)

      localStorage.removeItem("jwt")
      localStorage.removeItem("user")

      set({ loggedIn: false, jwt: null, user: null })
    } catch (error) {
      console.log(error)
    }
  },
  getCurrentUser: async () => {
    try {
      const response = await getCurrentUser(get().jwt)
      set({ loggedIn: true, jwt: get().jwt, user: response.data })
    } catch (error) {
      console.log(error)
    }
  },
}))

const useProfile = () => useAuthStore((state) => state.user) || {}
const useJwt = () => useAuthStore((state) => state.jwt) || null

export { useAuthStore, useProfile, useJwt }
