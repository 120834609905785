import React from "react"
import { theme, Typography } from "antd"

const { useToken } = theme
const { Text } = Typography

const PositivePromptText = ({ text }) => {
  const { token } = useToken()

  return (
    <Text copyable>
      <i
        className="fa-duotone fa-square-quote"
        style={{ color: token.colorSuccess, marginRight: token.marginXS }}
      />
      {text}
    </Text>
  )
}

export default PositivePromptText
