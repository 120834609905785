import { Space } from "antd"
import Steps from "./components/Steps"
import Sampler from "./components/Sampler"
import Height from "./components/Height"
import Width from "./components/Width"
import CfgScale from "./components/CfgScale"
import Seed from "./components/Seed"

const PromptParameters = () => {
  return (
    <Space direction="vertical" style={{ display: "flex", padding: "0.5rem" }}>
      <Seed />
      <Sampler />
      <Steps />
      <CfgScale />
      <Width />
      <Height />
    </Space>
  )
}

PromptParameters.displayName = "PromptParameters"

export default PromptParameters
