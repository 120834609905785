const defaultConfig = {
  placement: "bottomRight",
  duration: 5,
}
const useNotification = (notificationApi, config = defaultConfig) => {
  const openErrorNotification = (message, description, customConfig = {}) => {
    notificationApi.error({
      message,
      description,
      ...config,
      ...customConfig,
    })
  }

  const openNoticeNotification = (message, description, customConfig = {}) => {
    notificationApi.info({
      message,
      description,
      ...config,
      ...customConfig,
    })
  }

  return { openErrorNotification, openNoticeNotification }
}

export { useNotification }
