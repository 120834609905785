import ReactGA from "react-ga4"
import "./App.css"
import "./index.css"
import theme from "./theme"

import { ConfigProvider } from "antd"

import { AppLayout } from "./layouts"

if (process.env.NODE_ENV === "production") ReactGA.initialize("G-6G1X1NQQS4")

function App() {
  return (
    <ConfigProvider theme={{ ...theme }}>
      <AppLayout />
    </ConfigProvider>
  )
}

App.displayName = "App"
App.whyDidYouRender = true

export default App
