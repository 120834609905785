import { theme, Avatar, Skeleton, Card, Row, Col } from "antd"

import { usePromptStateById } from "@store/assisted_prompt.store"

import ErrorResult from "@shared/components/ErrorResult"
import SDPrompt from "./SDPrompt"
import ResultImages from "./ResultImages"

const { useToken } = theme

const Prompt = ({ id, message }) => {
  const state = usePromptStateById(id)

  const { token } = useToken()
  const boxStyle = {
    backgroundColor: token.colorBgContainer,
    borderColor: token.colorBorderSecondary,
    borderRadius: token.borderRadiusLG,
    padding: token.paddingLG,
    marginBottom: token.marginLG,
  }

  const messageStyle = {
    marginBottom: token.marginLG,
  }

  const avatarStyle = {
    backgroundColor: token.colorBgElevated,
  }

  const Response = () => {
    switch (state) {
      case "pending":
        return <Skeleton active />
      case "processing":
        return <Skeleton active />
      case "attempt_succeded":
        return <SDPrompt id={id} />
      case "attempt_failed":
        return <ErrorResult />
      case "completed":
        return <SDPrompt id={id} />
      default:
        return <Skeleton active />
    }
  }

  return (
    <div className="prompt" style={boxStyle}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card style={messageStyle}>
            <Card.Meta
              avatar={
                <Avatar
                  icon={<i className="fa-thin fa-user"></i>}
                  style={avatarStyle}
                />
              }
              description={message}
            />
          </Card>
          {Response()}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ResultImages id={id} />
        </Col>
      </Row>
    </div>
  )
}

Prompt.displayName = "Prompt"
Prompt.whyDidYouRender = true

export default Prompt
