import React, { useEffect, useRef, useState, useContext } from "react"
import { Input, Button, Flex } from "antd"

import { SubscriptionContext } from "@cable"
import {
  useAssistedPromptStore,
  useCanAcceptNewPrompt,
} from "@store/assisted_prompt.store"
import { useAdvancedPromptStore } from "@store/advanced_prompt.store"

import PromptParameters from "./PromptParameters"

import "../index.css"

const placeholderVariants = [
  "An elegant ballroom scene inspired by Art Deco",
  "A vibrant street in Tokyo during cherry blossom season, with a touch of anime aesthetics",
  "A surreal space landscape, evoking the feel of Salvador Dalí's paintings",
  "A serene Zen garden with elements of traditional Japanese art",
  "An abstract composition in the style of Piet Mondrian",
  "A bustling medieval market scene, inspired by Pieter Bruegel the Elder",
  "A portrait in the bold and colorful style of Kehinde Wiley",
  "A dreamy, star-filled sky in the style of Van Gogh’s 'Starry Night'",
  "A peaceful cabin in the woods, in the cozy style of Bob Ross",
  "A dynamic superhero action scene, reminiscent of comic book art",
  "A romantic Parisian café in the early 1900s, inspired by Impressionism",
  "A vibrant African savannah at sunset, by Thomas Kinkade's paintings",
  "An ancient Egyptian tomb with hieroglyphics, in a realistic historical style",
  "A whimsical candy land, inspired by the art of Mary Blair",
  "A gothic cathedral interior, in the style of stained glass art",
  "A post-apocalyptic landscape with a sci-fi twist",
  "An Art Nouveau inspired portrait with intricate floral designs",
  "A digital painting of a young explorer in a lush, mystical forest, in the style of Tsuaii",
  "A high-fashion photography shoot in a bustling urban landscape, featuring an avant-garde model",
  "A vibrant anime scene with a cute witch preparing magical potions in her enchanted shop",
  "A dynamic sci-fi setting showcasing a giant mech patrolling a futuristic city",
  "A charming cartoon scene of a friendly robot in a whimsical candy land",
  "A sleek cyberpunk street filled with neon lights, featuring a rogue hacker character",
  "A serene beach scene in a watercolor style, with a contemplative artist sketching by the shore",
  "An epic superhero showdown in a comic book style, set in a sprawling metropolis",
  "A minimalist digital portrait of a young woman, inspired by the work of Pascal Campion",
  "A breathtaking mountaintop view in a travel photography style, with an adventurous climber celebrating the ascent",
  "An action-packed space battle scene in a graphic novel style, featuring a daring space pilot",
  "A magical realism scene with a mystical deer in an enchanted forest, captured in a dreamy photographic style",
  "A retro-futuristic diner scene in a pop art style, with a charismatic waitress on roller skates",
  "A gothic fantasy setting with a mysterious vampire character, in a dark digital art style",
  "A lively street dance scene set in an urban graffiti-splashed alley, featuring a talented breakdancer",
  "A surreal underwater world in a photorealistic style, with a mermaid exploring a sunken shipwreck",
  "A heartwarming scene of a grandmother and grandchild baking together, in a Norman Rockwell-inspired style",
  "A dramatic post-apocalyptic landscape with a lone survivor and their faithful dog companion",
  "A peaceful zen garden in a modern illustration style, featuring a meditating monk",
  "A bustling medieval market in a fantasy digital painting, with a jovial bard entertaining the crowd",
]

const ChatInput = () => {
  const subscription = useContext(SubscriptionContext)
  const { createPrompt } = useAssistedPromptStore()
  const inputRef = useRef(null)
  const [message, setMessage] = useState("")
  const enableInput = useCanAcceptNewPrompt()
  const [randomPlaceholder, setRandomPlaceholder] = useState("")

  const { width, height, steps, cfg_scale, seed, sampler } =
    useAdvancedPromptStore((state) => state)

  const promptParams = () => ({
    width,
    height,
    steps,
    cfg_scale,
    seed,
    sampler,
    message,
  })

  useEffect(() => {
    // Focus on the input area when the page loads or when input changes to enabled
    if (enableInput) {
      inputRef.current.focus()
      setRandomPlaceholder(
        placeholderVariants[
          Math.floor(Math.random() * placeholderVariants.length)
        ],
      )
    } else {
      setRandomPlaceholder("")
    }
  }, [enableInput])

  // Submit the input text to the server for processing
  const handleInputSubmit = (e) => {
    e.preventDefault()
    if (message.length > 0) {
      subscription.perform("assisted_prompt_create", promptParams())
      setMessage("")
    }
  }

  const handleChange = (e) => {
    setMessage(e.target.value)
  }

  return (
    <Flex gap="small" style={{ width: "100%" }}>
      <Input.TextArea
        rows={1}
        autoSize={{ minRows: 1, maxRows: 4 }}
        placeholder={randomPlaceholder}
        disabled={!enableInput}
        value={message}
        onChange={handleChange}
        onPressEnter={handleInputSubmit}
        ref={inputRef}
      />
      <Button
        type="primary"
        onClick={handleInputSubmit}
        style={{ marginLeft: 8 }}
        icon={<i className="fa-solid fa-paper-plane" />}
        disabled={!enableInput}
      />
      <PromptParameters />
    </Flex>
  )
}

ChatInput.displayName = "ChatInput"
ChatInput.whyDidYouRender = true

export default ChatInput
