import { Button, Popover, Descriptions } from "antd"
import CopyToClipboardButton from "@shared/components/CopyToClipboardButton"
import PositivePromptText from "@shared/components/PositivePromptText"

const InfoAction = ({ image }) => {
  const { cfg_scale, steps, sampler, seed, height, width, positive_prompt } =
    image

  const items = [
    {
      key: 3,
      label: "Seed",
      children: (
        <>
          <span>{seed}</span>
          <CopyToClipboardButton text={seed} type="text" />
        </>
      ),
    },
    {
      key: 4,
      label: "Cfg",
      children: cfg_scale,
    },
    {
      key: 5,
      label: "Steps",
      children: steps,
    },
    {
      key: 6,
      label: "Sampler",
      children: sampler,
    },
    {
      key: 7,
      label: "Width",
      children: width,
    },
    {
      key: 8,
      label: "Height",
      children: height,
    },
  ]

  return (
    <Popover
      content={
        <div style={{ maxWidth: "50vw" }}>
          <PositivePromptText text={positive_prompt} />
          <Descriptions items={items} bordered />
        </div>
      }
    >
      <Button type="text" icon={<i className="fa-duotone fa-info-circle" />} />
    </Popover>
  )
}

export default InfoAction
