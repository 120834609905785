import client from "./client"

const login = (values) => {
  return client.post("/users/sign_in", { user: values })
}

const signup = (values) => {
  return client.post("/users", { user: values })
}

const logout = (jwt) => {
  return client.get("/users/sign_out", { headers: { Authorization: jwt } })
}

const getCurrentUser = (jwt) => {
  return client.get("/users/me", { headers: { Authorization: jwt } })
}

export { login, signup, logout, getCurrentUser }
