import React, { useMemo } from "react"
import { Button, Result, Skeleton, Typography, Image as AntDImage } from "antd"

const { Text } = Typography

const Image = ({ state, image_url, error_message }) => {
  // useCheckNewReference(state, "Image state")

  const MemoImage = useMemo(() => {
    switch (state) {
      case "pending":
        return <Skeleton active={false} style={{ width: "100%" }} />
      case "failed":
        return (
          <Result
            style={{ paddingLeft: 0, paddingRight: 0 }}
            status="error"
            title="Error"
            subTitle={
              <Text type="danger">{error_message || "Unknown error"}</Text>
            }
            extra={
              <>
                <Text>Please report and we'll fix it ASAP</Text>
                <br />
                <Button
                  type="primary"
                  href="https://discord.com/channels/1075146214561038426/1188482329329545266"
                  icon={<i className="fa-brands fa-discord" />}
                  target="_blank"
                >
                  Report
                </Button>
              </>
            }
          />
        )
      case "completed":
        return <AntDImage src={image_url} />
      default:
        return <Skeleton active={true} style={{ width: "100%" }} />
    }
  }, [state, image_url]) // eslint-disable-line react-hooks/exhaustive-deps

  return MemoImage
}

Image.displayName = "Image"
Image.whyDidYouRender = true

export default Image
