import { Form, Slider } from "antd"
import { debounce } from "lodash"

import { useAdvancedPromptStore, useWidth } from "@store/advanced_prompt.store"

import ScrollableInputNumber from "@shared/components/ScrollableInputNumber"

const Width = () => {
  const [form] = Form.useForm()
  const width = useWidth()
  const setWidth = useAdvancedPromptStore((state) => state.setWidth)

  const fields = [
    {
      name: "width",
      value: width,
    },
  ]

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.width) {
      setWidth(changedValues.width)
    }
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item
        name="width"
        label="Width"
        style={{ marginBottom: 0 }}
        labelCol={{ span: 10 }}
      >
        <ScrollableInputNumber
          min={64}
          max={1024}
          name="width"
          form={form}
          callback={handleValuesChange}
        />
      </Form.Item>
      <Form.Item name="width">
        <Slider min={64} max={1024} />
      </Form.Item>
    </Form>
  )
}

Width.displayName = "Width"
Width.whyDidYouRender = true

export default Width
