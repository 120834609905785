import { useContext, useEffect } from "react"
import { theme, Avatar, Card, Skeleton, Typography } from "antd"

import { SubscriptionContext } from "@cable"
import { usePromptById } from "@store/stable_diffusion_prompt.store"
import { usePromptSDPIdById } from "@store/assisted_prompt.store"

import CopyToClipboardButton from "@shared/components/CopyToClipboardButton"

const { useToken } = theme
const { Text } = Typography

const SDPrompt = ({ id }) => {
  const subscription = useContext(SubscriptionContext)
  const promptId = usePromptSDPIdById(id)
  const { positive_prompt } = usePromptById(promptId)

  const { token } = useToken()
  const avatarStyle = {
    backgroundColor: token.colorBgElevated,
  }

  useEffect(() => {
    if (positive_prompt) return

    subscription.perform("get_prompt", { id: promptId })
  }, [promptId, positive_prompt, subscription])

  if (!positive_prompt) {
    return (
      <Card>
        <Skeleton active />
      </Card>
    )
  }

  return (
    <Card>
      <Card.Meta
        avatar={
          <Avatar
            icon={<i className="fa-thin fa-message-bot"></i>}
            style={avatarStyle}
          />
        }
        description={
          <>
            <Text>{positive_prompt}</Text>
            <CopyToClipboardButton
              text={positive_prompt}
              style={{ marginLeft: 8 }}
              type="text"
            />
          </>
        }
      />
    </Card>
  )
}

SDPrompt.displayName = "SDPrompt"
SDPrompt.whyDidYouRender = true

export default SDPrompt
