import React from "react"
import { Button, Result } from "antd"

const ErrorResult = ({ error }) => {
  const errorString = error || "Please report and we'll fix it ASAP"
  return (
    <Result
      status="error"
      title="Error"
      subTitle={errorString}
      extra={
        <Button
          type="primary"
          href="https://discord.com/channels/1075146214561038426/1188482329329545266"
          icon={<i className="fa-brands fa-discord" />}
          target="_blank"
        >
          Report
        </Button>
      }
    />
  )
}

export default ErrorResult
