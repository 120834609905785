import { Button, Tooltip } from "antd"
import { useAdvancedPromptStore } from "@store/advanced_prompt.store"
import { useNavStore } from "@store/nav.store"

const EditAction = ({ image }) => {
  const setPrompt = useAdvancedPromptStore((state) => state.setPrompt)
  const setPageByKey = useNavStore((state) => state.setPageByKey)
  const prompt = {
    width: image.width,
    height: image.height,
    steps: image.steps,
    cfg_scale: image.cfg_scale,
    sampler: image.sampler,
    seed: image.seed,
    positivePrompt: image.positive_prompt,
    negativePrompt: image.negative_prompt,
  }
  const handleEdit = () => {
    setPrompt(prompt)
    setPageByKey("advanced")
  }
  return (
    <Tooltip title="Edit prompt">
      <Button
        type="text"
        icon={<i className="fa-duotone fa-edit" />}
        onClick={handleEdit}
      />
    </Tooltip>
  )
}

export default EditAction
