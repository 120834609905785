import { useCallback } from "react"
import { create } from "zustand"

// Represents a user's assisted prompt chat history containing messages from user and responses from the service
const useAssistedPromptStore = create((set) => ({
  // An array of AssistedPrompt instances in the following format:
  // {
  //   id: 1,
  //   state: 'processing',
  //   message: 'A girl by Julia Trotti',
  //   successful_attempt_id: 1,
  //   stable_diffusion_prompt_id: 1,
  //   created_at: '2021-06-01T00:00:00.000Z',
  //   updated_at: '2021-06-01T00:00:00.000Z',
  //   generated_image_ids: [1, 2, 3],
  // }
  prompts: {},
  synced: false,
  promptsIds: [],
  addPrompt: (prompt) => {
    set((state) => {
      const isNew = !state.prompts[prompt.id]
      return {
        ...state,
        prompts: { ...state.prompts, [prompt.id]: prompt },
        promptsIds: isNew
          ? [...state.promptsIds, { id: prompt.id, message: prompt.message }]
          : state.promptsIds,
      }
    })
  },
  setPrompts: (prompts) => {
    set((state) => ({
      ...state,
      synced: true,
      prompts,
      promptsIds: Object.values(prompts).reduce((acc, prompt) => {
        return [...acc, { id: prompt.id, message: prompt.message }]
      }, []),
    }))
  },
  prependPrompts: (prompts) => {
    const preparedPromopts = Object.values(prompts).reduce(
      (acc, prompt) => ({ ...acc, ...prompt }),
      {},
    )
    // Used for pagination to prepend prompts to the beginning of the list
    set((state) => ({
      ...state,
      prompts: { ...state.prompts, ...preparedPromopts },
      promptsIds: [
        ...Object.values(preparedPromopts).reduce((acc, prompt) => {
          return [...acc, { id: prompt.id, message: prompt.message }]
        }, []),
        ...state.promptsIds,
      ],
    }))
  },
}))

// Getter hooks
const usePromptById = (id) => {
  return useAssistedPromptStore(useCallback((state) => state.prompts[id], [id]))
}

const usePromptMessageById = (id) => {
  return useAssistedPromptStore(
    useCallback((state) => state.prompts[id]?.message, [id]),
  )
}

const usePromptStateById = (id) => {
  return useAssistedPromptStore(
    useCallback((state) => state.prompts[id]?.state, [id]),
  )
}

const usePromptSDPIdById = (id) => {
  return useAssistedPromptStore(
    useCallback((state) => state.prompts[id]?.stable_diffusion_prompt_id, [id]),
  )
}

const usePromptGeneratedImageIdsById = (id) => {
  return useAssistedPromptStore(
    useCallback((state) => state.prompts[id]?.generated_image_ids, [id]),
  )
}

const usePrompts = () => {
  return useAssistedPromptStore((state) => state.prompts)
}

const usePromptsIds = () => {
  return useAssistedPromptStore((state) => state.promptsIds)
}

const useSynced = () => {
  return useAssistedPromptStore((state) => state.synced)
}

// returns true if there are no prompts in :pending, :processing, or :attempt_errored state
const useCanAcceptNewPrompt = () => {
  const prompts = usePrompts()
  return (
    Object.values(prompts).filter(
      (prompt) =>
        prompt.state === "pending" ||
        prompt.state === "processing" ||
        prompt.state === "attempt_errored",
    ).length === 0
  )
}

export {
  useAssistedPromptStore,
  usePromptById,
  usePromptMessageById,
  usePromptStateById,
  usePromptSDPIdById,
  usePromptGeneratedImageIdsById,
  usePrompts,
  usePromptsIds,
  useSynced,
  useCanAcceptNewPrompt,
}
