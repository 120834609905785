import { theme, Form, Input, Button, Space, Tooltip } from "antd"
import { debounce } from "lodash"

import { useAdvancedPromptStore, useSeed } from "@store/advanced_prompt.store"

const { useToken } = theme

const Seed = () => {
  const [form] = Form.useForm()
  const setSeed = useAdvancedPromptStore((state) => state.setSeed)
  const seed = useSeed()

  const fields = [
    {
      name: "seed",
      value: seed,
    },
  ]

  const { token } = useToken()
  const tooltipColor = token.colorPrimary

  const handlePasteSeedButton = () => {
    navigator.clipboard.readText().then((text) => {
      form.setFieldValue("seed", text)
      setSeed(text)
    })
  }

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.seed) {
      setSeed(changedValues.seed)
    }
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Space.Compact>
        <Form.Item
          label={
            <Tooltip title="Seed" color={tooltipColor} placement="topRight">
              <i className="fa-duotone fa-dice"></i>
            </Tooltip>
          }
          name="seed"
        >
          <Input placeholder="Random" style={{ width: "100%" }} allowClear />
        </Form.Item>
        <Button
          onClick={handlePasteSeedButton}
          icon={<i className="fa-duotone fa-paste"></i>}
        />
      </Space.Compact>
    </Form>
  )
}

export default Seed
