import React from "react"
import { useRef } from "react"
import { theme, Image, Row, Col, Typography } from "antd"
import { usePromptById as useAdvancedPromptById } from "@store/advanced_prompt.store"
import {
  GeneratedImage,
  PositivePromptText,
  NegativePromptText,
} from "@shared/components"

const { useToken } = theme
const { Text } = Typography

const VectorPrompt = ({
  id,
  positivePromptTemplate,
  negativePromptTemplate,
  gridAxesTypes,
  gridAxes,
}) => {
  const containerRef = useRef(null)
  const { token } = useToken()

  const [vectorAxis, vectorAxisType] = gridAxesTypes.x
    ? ["x", gridAxesTypes.x]
    : ["y", gridAxesTypes.y]
  const vectorValues = gridAxes[vectorAxisType][vectorAxis]
  const { generated_image_ids: imageIds } = useAdvancedPromptById(id)

  const boxStyle = {
    backgroundColor: token.colorBgContainer,
    borderColor: token.colorBorderSecondary,
    borderRadius: token.borderRadiusLG,
    padding: token.paddingLG,
    marginBottom: token.marginLG,
  }

  const renderPositivePrompt = () => (
    <>
      {positivePromptTemplate
        .split(`__GRID_AXIS_${vectorAxis}__`)
        .map((part, index, array) => (
          <React.Fragment key={index}>
            {part}
            {index < array.length - 1 && <Text mark>{vectorValues[0]}</Text>}
          </React.Fragment>
        ))}
    </>
  )

  const vectorAxisTypeName =
    vectorAxisType === "token" ? "text" : vectorAxisType

  return (
    <div ref={containerRef} style={boxStyle}>
      <Row style={{ marginBottom: token.marginLG }}>
        <Col span={24}>
          <Text>Experiment #{id}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text>
            Testing {vectorAxisTypeName} with values: {vectorValues.join(", ")}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PositivePromptText text={renderPositivePrompt()} />
        </Col>
      </Row>
      {negativePromptTemplate && (
        <Row>
          <Col span={24}>
            <NegativePromptText text={negativePromptTemplate} />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Image.PreviewGroup
          preview={{ getContainer: () => containerRef.current }}
        >
          {imageIds.map((id) => (
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={4} key={id}>
              <GeneratedImage id={id} />
            </Col>
          ))}
        </Image.PreviewGroup>
      </Row>
    </div>
  )
}

VectorPrompt.displayName = "VectorPrompt"
VectorPrompt.whyDidYouRender = true

export default VectorPrompt
