import { theme } from "antd"

const { useToken } = theme

const useStyles = () => {
  const { token } = useToken()

  // Adwanced prompt styles
  const textStyle = {
    fontSize: token.fontSizeHeading4,
    lineHeight: token.lineHeightHeading4,
  }

  const placeholderStyle = {
    ...textStyle,
    color: token.colorTextPlaceholder,
  }

  const staticParagraphStyle = {
    ...textStyle,
    cursor: "edit",
    minHeight: "4em",
    // backgroundColor: token.colorBgLayout,
    backgroundColor: token.colorBgContainer,
    borderColor: token.colorPrimary,
    borderRadius: token.borderRadiusLG,
    borderStyle: "solid",
    borderWidth: 1,
    padding: token.paddingSM,
  }

  const popoverStyle = {
    maxWidth: "30em",
  }

  const synonymTreeStyle = {
    maxHeight: "40vh",
    overflowY: "auto",
  }

  const tokenStyle = {
    ...textStyle,
    borderBottom: `1px dotted ${token.colorPrimary}`,
    borderRadius: token.borderRadiusSM,
    paddingLeft: "0.1em",
    paddingRight: "0.1em",
    marginLeft: "-0.1em",
    marginRight: "-0.1em",
    cursor: "pointer",
    color: token.colorPrimary,
  }

  const axisTokenStyle = {
    ...tokenStyle,
    color: token.colorBgContainer,
    backgroundColor: token.colorPrimary,
  }

  // Display as a Textarea with minimal height = 3 lines of text
  const editableParagraphStyle = {
    cursor: "edit",
    minHeight: "4em",
    backgroundColor: token.colorBgContainer,
    borderColor: token.colorPrimary,
    borderRadius: token.borderRadiusLG,
    borderStyle: "solid",
    borderWidth: 1,
    fontSize: token.fontSizeHeading4,
    lineHeight: token.lineHeightHeading4,
    padding: token.paddingSM,
    marginBottom: "1em",
    marginTop: 0,
    insetInlineStart: "0px",
  }

  return {
    textStyle,
    placeholderStyle,
    staticParagraphStyle,
    editableParagraphStyle,
    popoverStyle,
    synonymTreeStyle,
    tokenStyle,
    axisTokenStyle,
  }
}

export { useStyles }
