import { useEffect, useRef, useState } from "react"
import { Popover } from "antd"
import rangy from "rangy"

const TextSelector = ({
  children,
  content,
  openCallback,
  textSelectedCallback,
}) => {
  const containerRef = useRef(null)
  const popupContainerRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [popoverStyle, setPopoverStyle] = useState({})

  const setVisibleWrapper = (value) => {
    if (openCallback) openCallback(value)
    setVisible(value)
  }

  const handleSelection = (event) => {
    let selectedText = ""
    let rect
    if (
      event.target.tagName.toLowerCase() === "textarea" ||
      event.target.tagName.toLowerCase() === "input"
    ) {
      const start = event.target.selectionStart
      const end = event.target.selectionEnd
      selectedText = event.target.value.substring(start, end)
      if (!selectedText) {
        setVisibleWrapper(false)
        textSelectedCallback("")
        return
      }

      rect = event.target.getBoundingClientRect()
    } else {
      const selection = rangy.getSelection()
      if (!selection.rangeCount || selection.isCollapsed) {
        setVisibleWrapper(false)
        textSelectedCallback("")
        return
      }

      selectedText = selection.toString()
      const range = rangy.createRange()
      range.selectNodeContents(event.target)
      rect = range.nativeRange.getBoundingClientRect()
    }

    const newStyle = {
      position: "fixed",
      top: parseInt(rect.top),
      left: parseInt(rect.left + rect.width / 2),
    }

    if (
      newStyle.top !== popoverStyle.top ||
      newStyle.left !== popoverStyle.left
    )
      setPopoverStyle(newStyle)

    if (!visible) setVisibleWrapper(true)
    textSelectedCallback(selectedText)
  }

  useEffect(() => {
    rangy.init()
    const container = containerRef.current
    container.addEventListener("mouseup", handleSelection)
    container.addEventListener("keyup", handleSelection)
    return () => {
      container.removeEventListener("mouseup", handleSelection)
      container.removeEventListener("keyup", handleSelection)
    }
  }, [])

  return (
    <div ref={containerRef}>
      {children}
      <div style={popoverStyle} ref={popupContainerRef}>
        <Popover
          content={content}
          open={visible}
          getPopupContainer={() => popupContainerRef.current}
          zIndex={1000}
        />
      </div>
    </div>
  )
}

export default TextSelector
