import { useEffect, useState, useRef, useContext, useCallback } from "react"
import { Virtuoso } from "react-virtuoso"
import { Skeleton } from "antd"

import { usePromptsIds, useSynced } from "@store/advanced_prompt.store"
import { SubscriptionContext } from "@cable"

// import { useCheckNewReference } from "@hooks"

import BatchPrompt from "./BatchPrompt"
import VectorPrompt from "./VectorPrompt"

const Timeline = () => {
  const subscription = useContext(SubscriptionContext)
  const virtuosoRef = useRef(null)
  const promptsIds = usePromptsIds()
  const synced = useSynced()
  const indexOffset = 10000
  const [state, setState] = useState({
    items: [],
    firstItemIndex: indexOffset,
  })

  // Track when promptsIds receives new items on top and decrease firstItemIndex by the number of new items
  useEffect(() => {
    if (promptsIds.length === 0) return

    const newItemsCount = promptsIds.length - state.items.length
    const receivedOnTop = promptsIds[0].id !== state.items[0]?.id
    if (state.items.length !== 0 && newItemsCount > 0 && receivedOnTop) {
      setState((state) => ({
        ...state,
        firstItemIndex: state.firstItemIndex - newItemsCount,
      }))
      // little delay to allow virtuoso to update
      setTimeout(() => {
        virtuosoRef.current.scrollToIndex(0)
      }, 300)
    }

    if (state.items.length === promptsIds.length) return

    setItems(promptsIds)
  }, [promptsIds]) // eslint-disable-line react-hooks/exhaustive-deps

  const endReached = useCallback(
    () => {
      const { id } = state.items[state.items.length - 1]
      subscription.perform("advanced_prompt_paginate_down_from", { id: id })
    },
    [state.items], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setItems = (items) => {
    setState((state) => {
      if (state.items.length === items.length) return state
      return { ...state, items: items }
    })
  }

  const itemContent = (_index, item) => {
    switch (item.kind) {
      case "batch":
        return <BatchPrompt key={item.id} {...item} />
      case "vector":
        return <VectorPrompt key={item.id} {...item} />
      default:
        return <div>Unknown item kind: {item.kind}</div>
    }
  }

  return (
    <>
      {synced ? (
        <Virtuoso
          ref={virtuosoRef}
          style={{ flexGrow: 1 }}
          firstItemIndex={state.firstItemIndex}
          initialTopMostItemIndex={0}
          data={state.items}
          endReached={endReached}
          itemContent={itemContent}
          atTopThreshold={600}
          computeItemKey={(_index, item) => item.id}
          increaseViewportBy={2000}
        />
      ) : (
        <Skeleton active={true} />
      )}
    </>
  )
}

Timeline.displayName = "Timeline"
Timeline.whyDidYouRender = false

export default Timeline
