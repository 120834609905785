import { create } from "zustand"

const useNlpStore = create((set) => ({
  // Token: { text: { id, lemma, text, pos, tag, definition, synonyms } }
  tokens: {},
  setTokens: (tokens) =>
    set((state) => ({ ...state, tokens: { ...state.tokens, ...tokens } })),
}))

const useNlpToken = (text) => {
  const token = useNlpStore((state) => state.tokens[text])
  return token
}

export { useNlpStore, useNlpToken }
