import { useRef } from "react"
import { theme, Image, Row, Col, Typography } from "antd"

import { usePromptById as useAdvancedPromptById } from "@store/advanced_prompt.store"

import {
  GeneratedImage,
  PositivePromptText,
  NegativePromptText,
} from "@shared/components"

const { useToken } = theme
const { Text } = Typography

const BatchPrompt = (params) => {
  const containerRef = useRef(null)
  const { id, positivePromptTemplate, negativePromptTemplate } = params
  const { generated_image_ids: imageIds } = useAdvancedPromptById(id)

  const { token } = useToken()
  const boxStyle = {
    backgroundColor: token.colorBgContainer,
    borderColor: token.colorBorderSecondary,
    borderRadius: token.borderRadiusLG,
    padding: token.paddingLG,
    marginBottom: token.marginLG,
  }
  const headerRowStyle = {
    marginBottom: token.marginLG,
  }
  const positiveIconStyle = {
    color: token.colorSuccess,
    marginRight: token.marginXS,
  }
  const negativeIconStyle = {
    color: token.colorError,
    marginRight: token.marginXS,
  }
  const imagesRowStyle = {
    marginTop: token.marginLG,
  }

  return (
    <div ref={containerRef} style={boxStyle}>
      <Row style={headerRowStyle}>
        <Col span={24}>
          <Text>Batch #{id}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PositivePromptText text={positivePromptTemplate} />
        </Col>
      </Row>
      {negativePromptTemplate && (
        <Row>
          <Col span={24}>
            <NegativePromptText text={negativePromptTemplate} />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={imagesRowStyle}>
        <Image.PreviewGroup
          preview={{ getContainer: () => containerRef.current }}
        >
          {imageIds.map((id) => (
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={4} key={id}>
              <GeneratedImage id={id} />
            </Col>
          ))}
        </Image.PreviewGroup>
      </Row>
    </div>
  )
}

BatchPrompt.displayName = "BatchPrompt"
BatchPrompt.whyDidYouRender = true

export default BatchPrompt
