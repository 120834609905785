import { Form, Segmented } from "antd"
import { useAdvancedPromptStore, useCount } from "@store/advanced_prompt.store"

const CountSlider = () => {
  const [form] = Form.useForm()
  const count = useCount()
  const setCount = useAdvancedPromptStore((state) => state.setCount)

  const fields = [
    {
      name: "count",
      value: count,
    },
  ]

  const options = [1, 2, 3, 4, 5, 6, 7, 8]

  const handleValuesChange = (changedValues) => {
    if (changedValues.count) {
      setCount(changedValues.count)
    }
  }

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item name="count" label="Count">
        <Segmented size="small" options={options} />
      </Form.Item>
    </Form>
  )
}

CountSlider.displayName = "CountSlider"
CountSlider.whyDidYouRender = true

export default CountSlider
