import { Form, Input } from "antd"
import {
  useAdvancedPromptStore,
  useNegativePrompt,
} from "@store/advanced_prompt.store"

const NegativePrompt = () => {
  const negativePrompt = useNegativePrompt()
  const setNegativePrompt = useAdvancedPromptStore(
    (state) => state.setNegativePrompt,
  )

  const { TextArea } = Input

  return (
    <Form.Item>
      <TextArea
        name="negativePrompt"
        placeholder="Negative prompt"
        autoSize={{ minRows: 1 }}
        value={negativePrompt}
        onChange={(e) => setNegativePrompt(e.target.value)}
      />
    </Form.Item>
  )
}

NegativePrompt.displayName = "NegativePrompt"

export default NegativePrompt
