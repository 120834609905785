import { useContext, useEffect, useMemo } from "react"
import { Typography } from "antd"
import nlp from "compromise/two"

import { SubscriptionContext } from "@cable"
import { useStyles } from "@hooks"
import { useNlpStore, useAdvancedPromptStore } from "@store"

import Token from "./Token"

const { Text, Paragraph } = Typography

// Renders a tokenized prompt with Popovers for tokens with definitions and synonyms previews
const StaticPrompt = ({ positivePrompt, editHandler, handleChange }) => {
  const subscription = useContext(SubscriptionContext)
  const nlpTokens = useNlpStore((state) => state.tokens)
  const { staticParagraphStyle, placeholderStyle } = useStyles()
  const gridAxesTypes = useAdvancedPromptStore((state) => state.gridAxesTypes)
  const gridAxes = useAdvancedPromptStore((state) => state.gridAxes)

  const isXaTokenAxis = gridAxesTypes.x === "token"
  const isYaTokenAxis = gridAxesTypes.y === "token"

  const isXhasTokens = isXaTokenAxis && gridAxes.token.x.length > 0
  const isYhasTokens = isYaTokenAxis && gridAxes.token.y.length > 0

  useEffect(() => {
    if (!positivePrompt) return

    // Replace grid axes placeholders with first grid axis value if grid axes are set
    let wrappedPrompt = positivePrompt

    if (isXhasTokens) {
      wrappedPrompt = wrappedPrompt.replace(
        "__GRID_AXIS_x__",
        gridAxes.token.x[0],
      )
    }
    if (isYhasTokens) {
      wrappedPrompt = wrappedPrompt.replace(
        "__GRID_AXIS_y__",
        gridAxes.token.y[0],
      )
    }

    subscription.perform("nlp_tokenize", { text: wrappedPrompt })
  }, [positivePrompt]) // eslint-disable-line react-hooks/exhaustive-deps

  // Removes trailing punctuation from a string
  const cleanItem = (item) => {
    if (typeof item !== "string") return item

    return item.replace(/[.,:;](\s*)$/, "$1")
  }

  // Replace tokens with TokenComponent
  const wrappedPrompt = useMemo(() => {
    if (!positivePrompt) return null

    // Collect offsets before replacing grid axes placeholders
    let offsets = {}
    nlp(positivePrompt)
      .terms()
      .out("offset")
      .forEach((term, index) => {
        const cleanedTerm = cleanItem(term.text)
        const offset = {
          ...term.offset,
          length: term.text.length + (cleanedTerm.length - term.text.length),
        }
        offsets[index] = offset
      })

    // Replace grid axes placeholders with first grid axis value if grid axes are set
    let wrappedPrompt = positivePrompt

    // Replacements offsets are needed to highlight the correct token
    let xReplacementStart = null
    let yReplacementStart = null
    if (isXhasTokens) {
      const replacement = gridAxes.token.x[0]
      xReplacementStart = positivePrompt.indexOf("__GRID_AXIS_x__")
      wrappedPrompt = wrappedPrompt.replace("__GRID_AXIS_x__", replacement)
    }
    if (isYhasTokens) {
      const replacement = gridAxes.token.y[0]
      yReplacementStart = positivePrompt.indexOf("__GRID_AXIS_y__")
      wrappedPrompt = wrappedPrompt.replace("__GRID_AXIS_y__", replacement)
    }

    // Tokenize prompt
    let doc = nlp(wrappedPrompt)
    let replacements = {}

    doc
      .terms()
      .out("offset") // TODO: we don't need to get offsets twice
      .forEach((term, index) => {
        const cleanedTerm = cleanItem(term.text)
        if (nlpTokens[cleanedTerm]) {
          replacements[cleanedTerm] = () => `__POPOVER_${index}__`
        }
      })
    let replacedArray = doc
      .wrap(replacements)
      .split(/(__POPOVER_\d+__)/)
      .filter(Boolean)

    Object.entries(replacements).forEach(([termText, placeholderFunc]) => {
      const placeholder = placeholderFunc()
      // get offsetIndex from placeholder
      const offsetIndex = placeholder.match(/__POPOVER_(\d+)__/)[1]
      const token = nlpTokens[termText]
      replacedArray = replacedArray.map((item, index) => {
        if (item === placeholder) {
          // Compare item offset with replacement offsets to determine if it's a grid axis
          const itemOffset = offsets[offsetIndex]
          return (
            <Token
              handleChange={handleChange}
              positivePrompt={positivePrompt}
              term={{ text: termText, offset: offsets[offsetIndex] }}
              token={token}
              key={index}
              isXAxis={itemOffset.start === xReplacementStart}
              isYAxis={itemOffset.start === yReplacementStart}
            />
          )
        } else {
          return item
        }
      })
    })

    return replacedArray
  }, [positivePrompt, nlpTokens]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paragraph onClick={editHandler} style={staticParagraphStyle}>
      {wrappedPrompt ? (
        wrappedPrompt
      ) : (
        <Text type="secondary" style={placeholderStyle}>
          Prompt
        </Text>
      )}
    </Paragraph>
  )
}

StaticPrompt.displayName = "StaticPrompt"

export default StaticPrompt
