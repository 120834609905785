import { Form, Slider } from "antd"
import { debounce } from "lodash"

import { useAdvancedPromptStore, useHeight } from "@store/advanced_prompt.store"

import ScrollableInputNumber from "@shared/components/ScrollableInputNumber"

const Height = () => {
  const [form] = Form.useForm()
  const height = useHeight()
  const setHeight = useAdvancedPromptStore((state) => state.setHeight)

  const fields = [
    {
      name: "height",
      value: height,
    },
  ]

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.height) {
      setHeight(changedValues.height)
    }
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item
        name="height"
        label="Height"
        style={{ marginBottom: 0 }}
        labelCol={{ span: 10 }}
      >
        <ScrollableInputNumber
          min={64}
          max={1024}
          form={form}
          name="height"
          callback={handleValuesChange}
        />
      </Form.Item>
      <Form.Item name="height">
        <Slider min={64} max={1024} />
      </Form.Item>
    </Form>
  )
}

Height.displayName = "Height"
Height.whyDidYouRender = true

export default Height
