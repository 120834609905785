import { Select, Space, Form } from "antd"

import { useAdvancedPromptStore } from "@store"

import GridNodes from "./GridNodes"

const GridParameters = () => {
  const [form] = Form.useForm()
  const gridAxesTypes = useAdvancedPromptStore((state) => state.gridAxesTypes)

  const typeOptions = [
    { value: "token", label: "Token" },
    { value: "seed", label: "Seed" },
  ]

  const fields = [
    {
      name: "xType",
      value: gridAxesTypes.x,
    },
    {
      name: "yType",
      value: gridAxesTypes.y,
    },
  ]

  return (
    <Form form={form} fields={fields} style={{ marginLeft: 8 }}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Form.Item
          name="xType"
          label={<i className="fas fa-x" />}
          style={{ marginBottom: 0 }}
        >
          <Select options={typeOptions} size="small" placeholder="Type" />
        </Form.Item>
        <Form.Item>
          <GridNodes axis="x" />
        </Form.Item>
        <Form.Item
          name="yType"
          label={<i className="fas fa-y" />}
          style={{ marginBottom: 0 }}
        >
          <Select options={typeOptions} size="small" placeholder="Type" />
        </Form.Item>
        <Form.Item>
          <GridNodes axis="y" />
        </Form.Item>
      </Space>
    </Form>
  )
}

export default GridParameters
