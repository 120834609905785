import { useState, useEffect } from "react"
import axios from "axios"
import { Button, Tooltip } from "antd"

// Icon button to download the image
const DownloadAction = ({ image }) => {
  const [disabled, setDisabled] = useState(true)
  const { seed, id, image_url } = image

  useEffect(() => {
    if (image_url) {
      setDisabled(false)
    }
  }, [image_url])

  const download = () => {
    const fileName = `${seed}-${id}.jpg`
    axios({
      url: image_url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName)
      link.click()
      window.URL.revokeObjectURL(url)
    })
  }
  return (
    <Tooltip title="Download">
      <Button
        type="text"
        icon={<i className="fa-duotone fa-download" />}
        onClick={download}
        disabled={disabled}
      />
    </Tooltip>
  )
}

export default DownloadAction
