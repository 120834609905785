import { createConsumer } from "@rails/actioncable"
import { host } from "@constants"
import SubscriptionContext from "./SubscriptionContext"

function createUserChannelSubscription(
  jwt,
  onConnected,
  onReceived,
  onRejected,
  onDisconnected,
) {
  const protocol = window.location.protocol
  const wsProtocol = protocol === "https:" ? "wss" : "ws"
  const tokenQuery = jwt ? `?token=${jwt}` : ""
  const consumer = createConsumer(`${wsProtocol}://${host}/cable${tokenQuery}`)
  return consumer.subscriptions.create(
    {
      channel: "UserChannel",
    },
    {
      connected() {
        onConnected()
      },
      disconnected() {
        onDisconnected()
      },
      rejected() {
        onRejected()
      },
      received(data) {
        onReceived(data)
      },
    },
  )
}

export { SubscriptionContext, createUserChannelSubscription }
