import { useCallback } from "react"
import { create } from "zustand"

const useGeneratedImageStore = create((set) => ({
  images: {},
  setImages: (images) => {
    set((state) => ({
      ...state,
      images,
    }))
  },
  addImage: (image) => {
    set((state) => ({
      ...state,
      images: { ...state.images, [image.id]: image },
    }))
  },
  addImages: (images) => {
    const preparedImages = Object.values(images).reduce(
      (acc, image) => ({ ...acc, ...image }),
      {},
    )
    set((state) => ({
      ...state,
      images: { ...state.images, ...preparedImages },
    }))
  },
}))

// Getter hooks
const useImageById = (id) => {
  return useGeneratedImageStore(
    useCallback((state) => state.images[id] || {}, [id]),
  )
}

export { useGeneratedImageStore, useImageById }
