import { Form, Slider } from "antd"
import { debounce } from "lodash"

import { useAdvancedPromptStore, useSteps } from "@store/advanced_prompt.store"

import ScrollableInputNumber from "@shared/components/ScrollableInputNumber"

const Steps = () => {
  const [form] = Form.useForm()
  const steps = useSteps()
  const setSteps = useAdvancedPromptStore((state) => state.setSteps)

  const fields = [
    {
      name: "steps",
      value: steps,
    },
  ]

  const handleValuesChange = debounce((changedValues) => {
    if (changedValues.steps) {
      setSteps(changedValues.steps)
    }
  }, 100)

  return (
    <Form form={form} fields={fields} onValuesChange={handleValuesChange}>
      <Form.Item
        name="steps"
        label="Steps"
        size="small"
        style={{ marginBottom: 0 }}
        labelCol={{ span: 10 }}
      >
        <ScrollableInputNumber
          min={1}
          max={50}
          name="steps"
          form={form}
          callback={handleValuesChange}
        />
      </Form.Item>
      <Form.Item name="steps">
        <Slider min={1} max={50} />
      </Form.Item>
    </Form>
  )
}

Steps.displayName = "Steps"
Steps.whyDidYouRender = true

export default Steps
