import React from "react"
import { Menu } from "antd"
import { useNavStore, usePageKey } from "@store/nav.store"
import { useAuthStore } from "@store/auth.store"

const LeftMenu = ({ mode }) => {
  const key = usePageKey()
  const setPageByKey = useNavStore((state) => state.setPageByKey)
  const { loggedIn } = useAuthStore((state) => state)

  const handleClick = (e) => {
    setPageByKey(e.key)
  }

  const items = loggedIn
    ? [
        {
          key: "assisted",
          label: "Assisted prompt",
        },
        {
          key: "advanced",
          label: "Advanced prompt",
        },
        {
          key: "library",
          label: "Library",
        },
      ]
    : []

  return (
    <Menu
      theme="dark"
      mode={mode}
      selectedKeys={[key]}
      onClick={handleClick}
      items={items}
      disabledOverflow={true}
    />
  )
}

export default LeftMenu
