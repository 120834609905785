import { useState, useEffect } from "react"
import { Switch, Row, Col, Form } from "antd"

import { useNegativePrompt } from "@store/advanced_prompt.store"

import CountSlider from "./components/CountSlider"
import PositivePrompt from "./components/PositivePrompt"
import NegativePrompt from "./components/NegativePrompt"
import GenerateButton from "./components/GenerateButton"

const PromptForm = () => {
  const [negaviveVisible, setNegativeVisible] = useState(false)
  const negativePrompt = useNegativePrompt()

  useEffect(() => {
    if (negativePrompt) {
      setNegativeVisible(true)
    }
  }, [negativePrompt])

  const handleNegativeSwitch = (checked) => {
    setNegativeVisible(checked)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <PositivePrompt />
          {negaviveVisible && <NegativePrompt />}
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
          <CountSlider />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4}>
          <Form.Item label="Negative">
            <Switch
              checked={negaviveVisible}
              onChange={handleNegativeSwitch}
              disabled={negativePrompt}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <GenerateButton />
        </Col>
      </Row>
    </>
  )
}

PromptForm.displayName = "PromptForm"
PromptForm.whyDidYouRender = true

export default PromptForm
