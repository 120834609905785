import React from "react"
import { Typography, Card, Col, Row, Layout } from "antd"

const { Content } = Layout

const Library = () => {
  const { Title } = Typography

  return (
    <Content>
      <Title level={3}>Library</Title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Library" bordered={false}>
            <p>Library</p>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}

export default Library
