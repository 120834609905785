import { useEffect, useState } from "react"
import { Typography } from "antd"

import { useStyles } from "@hooks"
import TextSelector from "@shared/components/TextSelector"

const { Paragraph } = Typography

// Renders a prompt as editable Paragraph
const EditablePrompt = ({ positivePrompt, onChange, onCancel }) => {
  const { editableParagraphStyle } = useStyles()
  const [state, setState] = useState({
    editing: true,
    changedValue: "",
    textSelectorVisible: false,
  })

  useEffect(() => {
    if (state.textSelectorVisible || state.editing) return

    if (state.changedValue) {
      onChange(state.changedValue)
    } else {
      onCancel()
    }
  }, [state, onChange, onCancel])

  const handleCancel = () => {
    onCancel()
  }

  const handleChange = (value) => {
    onChange(value)
  }

  const textSelectorOpenCallback = (value) => {
    // console.log("textSelectorOpenCallback", value)
  }

  const textSelectorContent = <p>Selector content</p>

  const textSelectedCallback = (value) => {
    // console.log("textSelectedCallback", value)
  }

  return (
    <TextSelector
      openCallback={textSelectorOpenCallback}
      content={textSelectorContent}
      textSelectedCallback={textSelectedCallback}
    >
      <Paragraph
        className="editable-prompt"
        style={editableParagraphStyle}
        editable={{
          onChange: handleChange,
          onCancel: handleCancel,
          editing: state.editing,
        }}
      >
        {positivePrompt}
      </Paragraph>
    </TextSelector>
  )
}

EditablePrompt.displayName = "EditablePrompt"

export default EditablePrompt
