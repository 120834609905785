import { useCallback } from "react"
import { create } from "zustand"

const allGridAxesTypes = [
  "token",
  "seed",
  "steps",
  "cfg_scale",
  "sampler",
  "width",
  "height",
]

const useAdvancedPromptStore = create((set) => ({
  positivePrompt: "",
  negativePrompt: "",
  width: 64,
  height: 64,
  steps: 20,
  cfg_scale: 7,
  sampler: "",
  seed: "",
  count: 2,
  gridAxesTypes: {
    x: null,
    y: null,
  },
  // TODO: Store each type of axis in a separate object
  //       to not loose the data when switching between types
  gridAxes: {
    ...allGridAxesTypes.reduce((acc, type) => {
      return {
        ...acc,
        [type]: {
          x: [],
          y: [],
        },
      }
    }, {}),
  },
  availableSamplers: [],
  prompts: {},
  synced: false,
  promptsIds: [],
  // Prompt form setters
  setPrompt: (values) => set((state) => ({ ...state, ...values })),
  setPositivePrompt: (value) => set({ positivePrompt: value }),
  setNegativePrompt: (value) => set({ negativePrompt: value }),
  setWidth: (value) => set({ width: value }),
  setHeight: (value) => set({ height: value }),
  setSteps: (value) => set({ steps: value }),
  setCfgScale: (value) => set({ cfg_scale: value }),
  setSampler: (value) => set({ sampler: value }),
  setSeed: (value) => set({ seed: value }),
  setCount: (value) => set({ count: value }),
  // Timeline setters
  setPrompts: (prompts) => {
    set((state) => ({
      ...state,
      synced: true,
      prompts,
      promptsIds: Object.values(prompts).reduce((acc, prompt) => {
        return [
          {
            id: prompt.id,
            kind: prompt.kind,
            positivePromptTemplate: prompt.positive_prompt_template,
            negativePromptTemplate: prompt.negative_prompt_template,
            gridAxes: prompt.grid_axes,
            gridAxesTypes: prompt.grid_axes_types,
            sdPromptsIds: prompt.stable_diffusion_prompt_ids,
          },
          ...acc,
        ]
      }, []),
    }))
  },
  prependPrompt: (prompt) => {
    set((state) => ({
      ...state,
      prompts: {
        ...state.prompts,
        [prompt.id]: prompt,
      },
      promptsIds: [
        {
          id: prompt.id,
          kind: prompt.kind,
          positivePromptTemplate: prompt.positive_prompt_template,
          negativePromptTemplate: prompt.negative_prompt_template,
          gridAxes: prompt.grid_axes,
          gridAxesTypes: prompt.grid_axes_types,
          sdPromptsIds: prompt.stable_diffusion_prompt_ids,
          imagesIds: prompt.generated_image_ids,
        },
        ...state.promptsIds,
      ],
    }))
  },
  appendPrompts: (prompts) => {
    set((state) => {
      const preparedPromopts = Object.values(prompts).reduce(
        (acc, prompt) => ({ ...acc, ...prompt }),
        {},
      )

      return {
        ...state,
        prompts: {
          ...state.prompts,
          ...preparedPromopts,
        },
        promptsIds: [
          ...state.promptsIds,
          ...Object.values(preparedPromopts).reduce((acc, prompt) => {
            return [
              {
                id: prompt.id,
                kind: prompt.kind,
                positivePromptTemplate: prompt.positive_prompt_template,
                negativePromptTemplate: prompt.negative_prompt_template,
                gridAxes: prompt.grid_axes,
                gridAxesTypes: prompt.grid_axes_types,
                sdPromptsIds: prompt.stable_diffusion_prompt_ids,
              },
              ...acc,
            ]
          }, []),
        ],
      }
    })
  },
  // Grid axes setters
  setGridAxis: (axis, values) => {
    set((state) => ({
      ...state,
      gridAxes: {
        ...state.gridAxes,
        [state.gridAxesTypes[axis]]: {
          ...state.gridAxes[state.gridAxesTypes[axis]],
          [axis]: values,
        },
      },
    }))
  },
  addToGridAxis: (axis, value) => {
    set((state) => ({
      ...state,
      gridAxes: {
        ...state.gridAxes,
        [state.gridAxesTypes[axis]]: [
          ...state.gridAxes[state.gridAxesTypes[axis]],
          value,
        ],
      },
    }))
  },
  removeFromGridAxis: (axis, value) => {
    set((state) => ({
      ...state,
      gridAxes: {
        ...state.gridAxes,
        [state.gridAxesTypes[axis]]: {
          ...state.gridAxes[state.gridAxesTypes[axis]],
          [axis]: state.gridAxes[state.gridAxesTypes[axis]][axis].filter(
            (v) => v !== value,
          ),
        },
      },
    }))
  },
  unsetGridAxis: (axis) => {
    set((state) => ({
      ...state,
      gridAxes: {
        ...state.gridAxes,
        [state.gridAxesTypes[axis]]: [],
      },
    }))
  },
  setGridAxesType: (axis, type, firstValue = null) => {
    set((state) => {
      let extraChanges = {}

      // If we switch from `token` type we need to replace
      // he axis placeholder in the positive or negative prompt
      // with the first value from the axis
      if (state.gridAxesTypes[axis] === "token") {
        const placeholder = `__GRID_AXIS_${axis}__`
        if (state.positivePrompt.includes(placeholder)) {
          extraChanges = {
            positivePrompt: state.positivePrompt.replace(
              placeholder,
              state.gridAxes.token[axis][0],
            ),
          }
        }

        if (state.negativePrompt.includes(placeholder)) {
          extraChanges = {
            negativePrompt: state.negativePrompt.replace(
              placeholder,
              state.gridAxes.token[axis][0],
            ),
          }
        }

        // When we switch from `token` type we need to remove
        // the axis values to prevent them from being assigned
        // to another word if choosen for the same axis later
        extraChanges = {
          ...extraChanges,
          gridAxes: {
            ...state.gridAxes,
            token: {
              ...state.gridAxes.token,
              [axis]: [],
            },
          },
        }
      }

      // if firstValue is provided we need to add it to the new axis
      if (firstValue) {
        extraChanges = {
          ...extraChanges,
          gridAxes: {
            ...state.gridAxes,
            [type]: {
              ...state.gridAxes[type],
              [axis]: [firstValue],
            },
          },
        }
      }

      return {
        ...state,
        ...extraChanges,
        gridAxesTypes: {
          ...state.gridAxesTypes,
          [axis]: type,
        },
      }
    })
  },
}))

// Getter hooks
const useAdvancedPrompt = () => {
  // returns the whole state object for form submission
  return useAdvancedPromptStore((state) => {
    const {
      availableSamplers,
      prompts,
      synced,
      promptsIds,
      positivePrompt,
      negativePrompt,
      ...rest
    } = state
    return {
      ...rest,
      positive_prompt: positivePrompt,
      negative_prompt: negativePrompt,
    }
  })
}

const usePromptById = (id) => {
  return useAdvancedPromptStore(useCallback((state) => state.prompts[id], [id]))
}

const usePrompts = () => {
  return useAdvancedPromptStore((state) => state.prompts)
}

const usePromptsIds = () => {
  return useAdvancedPromptStore((state) => state.promptsIds)
}

const useSynced = () => {
  return useAdvancedPromptStore((state) => state.synced)
}

const usePositivePrompt = () => {
  return useAdvancedPromptStore((state) => state.positivePrompt)
}

const useNegativePrompt = () => {
  return useAdvancedPromptStore((state) => state.negativePrompt)
}

const useCount = () => {
  return useAdvancedPromptStore((state) => state.count)
}

const useWidth = () => {
  return useAdvancedPromptStore((state) => state.width)
}

const useHeight = () => {
  return useAdvancedPromptStore((state) => state.height)
}

const useSteps = () => {
  return useAdvancedPromptStore((state) => state.steps)
}

const useCfgScale = () => {
  return useAdvancedPromptStore((state) => state.cfg_scale)
}

const useSampler = () => {
  return useAdvancedPromptStore((state) => state.sampler)
}

const useSeed = () => {
  return useAdvancedPromptStore((state) => state.seed)
}

const useAvailableSamplers = () => {
  return useAdvancedPromptStore((state) => state.availableSamplers)
}

export {
  useAdvancedPromptStore,
  useAdvancedPrompt,
  usePositivePrompt,
  useNegativePrompt,
  useCount,
  useWidth,
  useHeight,
  useSteps,
  useCfgScale,
  useSampler,
  useSeed,
  useAvailableSamplers,
  usePromptById,
  usePrompts,
  usePromptsIds,
  useSynced,
}
