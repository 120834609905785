import { useState } from "react"
import { Button, Tooltip } from "antd"

const CopyToClipboardButton = ({ text, ...rest }) => {
  const [tooltipText, setTooltipText] = useState("Copy")

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    setTooltipText("Done!")
    setTimeout(() => setTooltipText("Copy"), 1000)
  }

  return (
    <Tooltip title={tooltipText}>
      <Button
        icon={<i className="fa-duotone fa-copy" />}
        onClick={handleCopy}
        {...rest}
      />
    </Tooltip>
  )
}

export default CopyToClipboardButton
