import { useCallback } from "react"
import { create } from "zustand"

const useStableDiffusionPromptStore = create((set) => ({
  prompts: {},
  setPrompts: (prompts) => {
    set(() => ({ prompts }))
  },
  addPrompt: (prompt) => {
    set((state) => ({
      prompts: { ...state.prompts, [prompt.id]: prompt },
    }))
  },
  addPrompts: (prompts) => {
    const preparedPromopts = Object.values(prompts).reduce(
      (acc, prompt) => ({ ...acc, ...prompt }),
      {},
    )
    set((state) => ({
      prompts: { ...state.prompts, ...preparedPromopts },
    }))
  },
}))

// Getter hooks
const usePromptById = (id) => {
  return useStableDiffusionPromptStore(
    useCallback((state) => state.prompts[id] || { id }, [id]),
  )
}

export { useStableDiffusionPromptStore, usePromptById }
