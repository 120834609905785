import React, { useState } from "react"
import { useAuthStore, useProfile } from "@store/auth.store"
import { Menu, Modal } from "antd"
import LoginForm from "./LoginForm"
import Stats from "./Stats"

const Authentication = ({ mode }) => {
  const { loggedIn, login, signup, logout } = useAuthStore((state) => state)
  const profile = useProfile()
  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const loginItems = [
    {
      key: "login",
      label: "Login",
      onClick: showModal,
    },
  ]

  const profileItems = [
    {
      key: "status",
      label: <Stats />,
      children: [
        {
          key: "email",
          label: profile ? profile.email : "",
        },
        {
          key: "logout",
          label: "Logout",
          onClick: logout,
        },
      ],
    },
  ]

  return (
    <>
      <Menu
        selectable={false}
        theme="dark"
        mode={mode}
        style={{
          flex: 1,
          minWidth: 0,
        }}
        items={loggedIn ? profileItems : loginItems}
        data-testid="authentication"
        disabledOverflow={true}
      />
      <Modal title="Login" open={visible} onCancel={handleCancel} footer={null}>
        <LoginForm login={login} signup={signup} setVisible={setVisible} />
      </Modal>
    </>
  )
}

export default Authentication
