import { create } from "zustand"
import Landing from "@pages/Landing"
import Assisted from "@pages/Assisted"
import Advanced from "@pages/Advanced"
import Library from "@pages/Library"

const { history } = window

const useNavStore = create((set, get) => ({
  name: "Landing",
  key: "landing",
  path: "/",
  page: Landing,
  pages: [
    { name: "Landing", path: "/", key: "landing", page: Landing },
    {
      name: "Assisted prompt",
      path: "/assisted",
      key: "assisted",
      page: Assisted,
    },
    {
      name: "Advanced prompt",
      path: "/advanced",
      key: "advanced",
      page: Advanced,
    },
    { name: "Library", path: "/library", key: "library", page: Library },
  ],
  setPage: (page) => {
    set({ name: page.name, key: page.key, path: page.path, page: page.page })
  },
  setPageByKey: (key) => {
    const page = get().pages.find((page) => page.key === key)
    history.pushState({ path: page.path }, page.name, page.path)
    get().setPage(page)
  },
  setPageByPath: (path) => {
    const page = get().pages.find((page) => page.path === path)
    get().setPage(page)
  },
}))

const usePageKey = () => useNavStore((state) => state.key)
const usePagePath = () => useNavStore((state) => state.path)
const usePageName = () => useNavStore((state) => state.name)
const usePage = () => useNavStore((state) => state.page)

export { useNavStore, usePageKey, usePagePath, usePageName, usePage }
