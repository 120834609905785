import { useContext, useState, useEffect } from "react"
import { Form, Button } from "antd"

import { useAdvancedPrompt } from "@store/advanced_prompt.store"
import { SubscriptionContext } from "@cable"

const GenerateButton = () => {
  const subscription = useContext(SubscriptionContext)
  const advancedPrompt = useAdvancedPrompt()
  const [disabled, setDisabled] = useState(true)

  const { positive_prompt: positivePrompt, negative_prompt: negativePrompt } =
    advancedPrompt

  useEffect(() => {
    if (disabled && (positivePrompt || negativePrompt)) {
      setDisabled(false)
    } else if (!disabled && !positivePrompt && !negativePrompt) {
      setDisabled(true)
    }
  }, [positivePrompt, negativePrompt]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGenerateClick = () => {
    subscription.perform("advanced_prompt_create", advancedPrompt)
  }

  return (
    <Form.Item>
      <Button
        type="primary"
        onClick={handleGenerateClick}
        block
        disabled={disabled}
      >
        Generate
      </Button>
    </Form.Item>
  )
}

export default GenerateButton
