import { useState } from "react"

import {
  useAdvancedPromptStore,
  usePositivePrompt,
} from "@store/advanced_prompt.store"

import StaticPrompt from "./StaticPrompt"
import EditablePrompt from "./EditablePrompt"

import "./index.css"

const PositivePrompt = () => {
  const [editing, setEditing] = useState(false)

  const positivePrompt = usePositivePrompt()
  const setPositivePrompt = useAdvancedPromptStore(
    (state) => state.setPositivePrompt,
  )

  const switchToEditing = () => {
    setEditing(true)
  }

  const handleCancel = () => {
    setEditing(false)
  }

  const handleChange = (value) => {
    setPositivePrompt(value)
    setEditing(false)
  }

  return editing ? (
    <EditablePrompt
      positivePrompt={positivePrompt}
      onChange={handleChange}
      onCancel={handleCancel}
    />
  ) : (
    <StaticPrompt
      positivePrompt={positivePrompt}
      handleChange={handleChange}
      editHandler={switchToEditing}
    />
  )
}

PositivePrompt.displayName = "PositivePrompt"

export default PositivePrompt
