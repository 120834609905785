import { Typography, Popover, Tree, Space, Divider, Checkbox } from "antd"

import { useStyles } from "@hooks"
import { useAdvancedPromptStore } from "@store"

const { Text, Title } = Typography

// Tokens with definitions and synonyms
const Token = ({
  term,
  token,
  handleChange,
  positivePrompt,
  isXAxis,
  isYAxis,
}) => {
  const { text, offset } = term
  const { tokenStyle, popoverStyle, synonymTreeStyle, axisTokenStyle } =
    useStyles()
  const gridAxes = useAdvancedPromptStore((state) => state.gridAxes)
  const gridAxesTypes = useAdvancedPromptStore((state) => state.gridAxesTypes)
  const [setGridAxis, setGridAxesType] = useAdvancedPromptStore((state) => [
    state.setGridAxis,
    state.setGridAxesType,
  ])
  // X and Y checked if gridAxes is not empty
  const xChecked = gridAxesTypes.x === "token" && gridAxes.token.x.length > 0
  const yChecked = gridAxesTypes.y === "token" && gridAxes.token.y.length > 0

  if (!token || !token.senses || token.senses.length === 0) return text

  // Accepts start and end indexes of a token and a replacement string
  // Replaces the token with the replacement string in the prompt
  // and calls handleChange with the new prompt
  const replaceHandler = (replacementOffset, replacement) => {
    const { start, length } = replacementOffset
    const newPrompt =
      positivePrompt.slice(0, start) +
      replacement +
      positivePrompt.slice(start + length)

    handleChange(newPrompt)
  }

  // Prevents click on Popover from propagating to the parent
  const handleClick = (e) => {
    e.stopPropagation()
  }

  const isAxisToken = (xChecked && isXAxis) || (yChecked && isYAxis)

  const handleSelect = (_keys, info) => {
    // Do nothing if it is an axis token
    if (isAxisToken) return

    // Triggers replacement of token with selected synonym
    replaceHandler(offset, info.node.title)
  }

  const isDisabledCheckbox = (synonym) =>
    // Disable checkbox if it is a current token
    synonym === text ||
    // Disable checkbox if max number of tokens is reached
    (isXAxis &&
      !gridAxes.token.x.includes(synonym) &&
      gridAxes.token.x.length === 10) ||
    (isYAxis &&
      !gridAxes.token.y.includes(synonym) &&
      gridAxes.token.y.length === 10)

  const synonymsTree = token.senses.reduce((acc, sense) => {
    const synonyms = sense.synonyms.reduce((synAcc, synonym) => {
      synAcc.push({
        title: synonym,
        selectable: !isAxisToken,
        key: `sense-${sense.id}-synonym-${synonym}`,
        value: synonym,
        disableCheckbox: isDisabledCheckbox(synonym),
      })
      return synAcc
    }, [])

    acc.push({
      title: sense.definition,
      key: `sense-${sense.id}`,
      selectable: false,
      children: synonyms,
      value: null,
    })
    return acc
  }, [])

  // Iterate over all senses and synonyms and check if the token is in the grid
  const checkedKeys = token.senses.reduce((acc, sense) => {
    const synonyms = sense.synonyms.reduce((synAcc, synonym) => {
      if (
        (isXAxis && gridAxes.token.x.includes(synonym)) ||
        (isYAxis && gridAxes.token.y.includes(synonym))
      ) {
        synAcc.push(`sense-${sense.id}-synonym-${synonym}`)
      }
      return synAcc
    }, [])

    // check parent node if all children are checked
    if (synonyms.length === sense.synonyms.length) {
      acc.push(`sense-${sense.id}`)
    }

    return [...acc, ...synonyms]
  }, [])

  const handleCheck = (_keys, data) => {
    const { checked, checkedNodes, node } = data

    let checkedValues = [{ value: text }, ...checkedNodes]
      .map((nod) => nod.value)
      .filter(
        (value, index, self) => value !== null && self.indexOf(value) === index,
      )

    // If unchecked, remove value of unchecked node
    if (!checked) {
      checkedValues = checkedValues.filter((value) => value !== node.value)
    }

    // cut to 10 allowed per axis
    const values = checkedValues.slice(0, 10)
    isXAxis ? setGridAxis("x", values) : setGridAxis("y", values)
  }

  const handleAxisChange = (axis, state) => {
    if (state) {
      setGridAxesType(axis, "token", text)
      replaceHandler(offset, `__GRID_AXIS_${axis}__`)
    } else {
      setGridAxesType(axis, null)
      // Offset has the length of placeholder string
      const placeholderOffset = { start: offset.start, length: 15 }
      replaceHandler(placeholderOffset, text)
    }
  }

  const handleXAxisChange = (e) => {
    handleAxisChange("x", e.target.checked)
  }

  const handleYAxisChange = (e) => {
    handleAxisChange("y", e.target.checked)
  }

  return (
    <Popover
      placement="bottom"
      title={
        <Space>
          <Text style={tokenStyle}>{text}</Text>
          <Text>{token.pos}</Text>
        </Space>
      }
      content={
        <div style={popoverStyle} onClick={handleClick}>
          <Title level={5}>Grid options</Title>
          <Checkbox
            checked={xChecked}
            disabled={xChecked && !isXAxis}
            onChange={handleXAxisChange}
          >
            Iterate on X axis
          </Checkbox>
          <Checkbox
            checked={yChecked}
            disabled={yChecked && !isYAxis}
            onChange={handleYAxisChange}
          >
            Iterate on Y axis
          </Checkbox>
          <Divider />
          <Title level={5}>Synonyms</Title>
          <Tree
            treeData={synonymsTree}
            onSelect={handleSelect}
            onCheck={handleCheck}
            style={synonymTreeStyle}
            checkable={isAxisToken}
            autoExpandParent={true}
            checkedKeys={checkedKeys}
          />
        </div>
      }
    >
      <Text style={isXAxis || isYAxis ? axisTokenStyle : tokenStyle}>
        {text}
      </Text>
    </Popover>
  )
}

export default Token
